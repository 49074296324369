import styles from "./Services.module.scss";
import style from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Services() {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25%",
    infinite: true,
    responsive: [
      { breakpoint: 592, settings: { centerMode: true, centerPadding: "15%" } },
    ],
  };

  return (
    <section className={styles.services}>
      <div className={styles["services-second"]}>
        <div className={styles.carosel}>
          <Slider {...settings} className={styles.slick}>
            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>Installation</h2>

                <img src="/images/pexels-40.jpg" />
                <p>
                  We take the hassle out of the installation process, allowing
                  you to enjoy your new floor without delay or the stress.
                </p>

                <button
                  onClick={() => navigate("/services?services=Installation")}
                >
                  View More
                </button>
              </div>
            </div>

            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>Refinish</h2>

                <img src="/images/pexels-30.jpg" />

                <p>
                  Hardwood allowes customers to breathe new life into their
                  existing floors, restoring their natural beauty and durability
                </p>

                <button onClick={() => navigate("/services?services=Refinish")}>
                  View More
                </button>
              </div>
            </div>

            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>Repair</h2>

                <img src="/images/pexels-31.jpg" />

                <p>
                  Don’t let cracks and scratches ruin your floor! Let us fix it
                  for you!
                </p>

                <button onClick={() => navigate("/services?services=Repair")}>
                  View More
                </button>
              </div>
            </div>
          </Slider>
        </div>

        <div className={styles["services-grid"]}>
          <div className={styles["services-grid-item"]}>
            <h2>Installation</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-40.jpg" />
              </span>
            </div>

            <p>
              We take the hassle out of the installation process, allowing you
              to enjoy your new floor without delay or the stress
            </p>

            <button onClick={() => navigate("/services?services=Installation")}>
              View More
            </button>
          </div>

          <div className={styles["services-grid-item"]}>
            <h2>Refinish</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-30.jpg" />
              </span>
            </div>

            <p>
              Hardwood allowes customers to breathe new life into their existing
              floors, restoring their natural beauty and durability
            </p>

            <button onClick={() => navigate("/services?services=Refinish")}>
              View More
            </button>
          </div>

          <div className={styles["services-grid-item"]}>
            <h2>Repair</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-31.jpg" />
              </span>
            </div>

            <p>
              Don’t let cracks and scratches ruin your floor! Let us fix it for
              you!
            </p>

            <button onClick={() => navigate("/services?services=Repair")}>
              View More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Services;
