import { useState } from "react";
import ModalBase from "./../common/Modal";
import styles from "./GalleryModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function GalleryModal(props) {
  // const [index, setIndex] = useState(props.index);
// console.log(props.photos[props.index])
  function back(){
    if(props.index - 1 == -1){
        props.setIndex(props.photos.length - 1)
    }
    else{
      props.setIndex(props.index - 1);
    }
  }

  function next() {
      if(props.index + 1 == props.photos.length){
          props.setIndex(0)
      }
      else{
        props.setIndex(props.index + 1);
      }
        
  }

  function handleKeyDown(code){
    if(code == "ArrowRight"){
      next()
    }
    else if(code == "ArrowLeft"){
      back()
    }
    else if(code == "Escape"){
      props.setLightboxOpen(false)
    }
  }
  
  return (
    <div className={styles["modal-container"]} onKeyDown={(event) => handleKeyDown(event.code)}>
      <ModalBase
        isOpen={props.lightboxOpen}
        close={() => props.setLightboxOpen(false)}
      >
        <div className={styles["modal-content"]} tabIndex="0">
            <div className={styles.main}>
          <span onClick={back}><FontAwesomeIcon icon={faArrowLeft}/></span>
          <img src={props.photos[props.index]} />
          <span onClick={next}><FontAwesomeIcon icon={faArrowRight}/></span>
          </div>
          <h6>{props.index + 1} of {props.photos.length}</h6>

        </div>
      </ModalBase>
    </div>
  );
}

export default GalleryModal;
