import styles from "./HowItWorks.module.scss";
import { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import Hardwood from "./Hardwood";
import Vinyl from "./Vinyl";
import Laminate from "./Laminate";
import SPC from "./SPC";

function Products(props) {
  document.title = "My Flooring Guy Now | Services";
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const ref = useRef(null);

  let location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const q = params.get("Products");

  // const validQuery = ["Hardwood", "Laminate", "Vinyl", "SPC"];

  const [selectedTab, setSelectedTab] = useState(
    props.product ? props.product : "Hardwood"
  );

  const scrollToDiv = () => {
    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  function toggleActive(name) {
    setSelectedTab(name);
  }

  return (
    <section className={styles["hiw-section"]}>
      <div>
        <div className={styles["hiw-main"]}>
          <div className={styles["hiw-top"]}>
            <h2>Products</h2>
          </div>
          <div className={styles["hiw-bottom"] + " " + "scroll-div-target"}>
            <div
              onClick={() => {
                toggleActive("Hardwood");
                navigate("./../hardwood");
                scrollToDiv();
              }}
              className={selectedTab == "Hardwood" && styles["active"]}
            >
              <span className={styles.text}>Hardwood</span>
            </div>
            <div
              onClick={() => {
                toggleActive("Laminate");
                navigate("./../laminateflooring");
                scrollToDiv();
              }}
              className={selectedTab == "Laminate" && styles["active"]}
            >
              <span className={styles.text}>Laminate</span>
            </div>
            <div
              onClick={() => {
                toggleActive("SPC");
                navigate("./../spc-tile-flooring");
                scrollToDiv();
              }}
              className={selectedTab == "SPC" && styles["active"]}
            >
              <span className={styles.text}>SPC</span>
            </div>
            <div
              onClick={() => {
                toggleActive("Vinyl");
                navigate("./../vinyl-tile-flooring");
                scrollToDiv();
              }}
              className={selectedTab == "Vinyl" && styles["active"]}
            >
              <span className={styles.text}>Vinyl</span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ scrollMarginTop: "180px" }} ref={ref}>
        {selectedTab === "Hardwood" && <Hardwood />}
        {selectedTab === "Laminate" && <Laminate />}
        {selectedTab === "SPC" && <SPC />}
        {selectedTab === "Vinyl" && <Vinyl />}
      </div>
    </section>
  );
}

export default Products;
