import styles from "./Blog.module.scss";

function Blog(props) {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <h2>{props.title}</h2>
        <span>{new Date(props.date).toDateString()}</span>
      </div>
      <p>{props.description}</p>
      <div className={styles["align-right"]}>
        <span className={styles.btn} onClick={props.viewDetails}>
          More Details
        </span>
      </div>
    </div>
  );
}

export default Blog;
