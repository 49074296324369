import styles from "./../styles/Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faInstagram, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const navigate = useNavigate();
  return (
    <footer>
      <div className={styles["footer-grid"]}>
        <div className={styles["grid-item"]}>
          <h4>ABOUT</h4>
          <span className={styles["left-link"]} onClick={() => navigate("how-it-works")}>How It Works</span>
          <span className={styles["left-link"]} onClick={() => navigate("products")}>Products</span>
          <span className={styles["left-link"]} onClick={() => navigate("services")}>Services</span>
          <span className={styles["left-link"]} onClick={() => navigate("gallery")}>Gallery</span>
          <span className={styles["left-link"]} onClick={() => navigate("privacy")}>Privacy Policy</span>
          <span className={styles["left-link"]} onClick={() => navigate("blog")}>Blog</span>

        </div>

        <div className={styles["grid-item"]}>
          <h4>CONTACT INFO</h4>
          <a href="tel:+17147948099" className={styles["right-link"]}><FontAwesomeIcon icon={faPhone} /> <span>(714) 794-8099</span></a>
          <a href="mailto:office@myflooringguynow.com" className={styles["right-link"]}><FontAwesomeIcon icon={faEnvelope} /> <span>office@myflooringguynow.com</span></a>
          <a href="https://www.instagram.com/myflooringguy/" target="_blank" className={styles["right-link"]}><FontAwesomeIcon style={{fontSize: "20px", width: "20px"}} icon={faInstagram} /> <span>@myflooringguy</span></a>
        </div>

        {/* <div className={styles["grid-item"]}>
          <img src="/main-logo.png"/>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
