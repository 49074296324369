import { useNavigate } from "react-router-dom";
import styles from "./LandingPage1.module.scss";
import ScrollButton from "./../common/ScrollButton";

function LandingPage1(props) {
  const navigate = useNavigate();

  return (
    <div className={styles["landing-container"]}>
      <div className={styles["landing-main"]}>
        <div>
          <h1>Affordable Floor Installation In {props.data.title}</h1>
          <p>
            If you live in {props.data.title} and need a new floor, we’re the team to
            trust. With decades of experience, we do floor installation better,
            faster, and more affordably than the competition. Turn to My
            Flooring Guy for all of your floor installation needs!
          </p>
          <button onClick={() => navigate("/get-estimate")}>
            Get Your Free Estimate
          </button>
        </div>
        <div>
          <div className={styles["landing-flex"]}>
            <img src="home-main.jpg" />
            <div>
              <h2>
                Looking for fast, affordable floor installation in {props.data.title}?
                You’re in the right place.
              </h2>
              <p>
                A new floor can make a home look 10 or even 20 years younger,
                significantly increase property value, and make your place the
                envy of the neighbors. Combine its value with its affordability,
                and a new floor can one of the best decisions you make for your
                home. If you live in {props.data.title} and are looking for floor
                installation of any kind– including hardwood, SPC, laminate, or
                vinyl– the team at My Flooring Guy is your answer. As a full
                service floor installer, we can help you pick the right
                materials and install, install the flooring you bring to us, or
                simply provide you with a custom, no-obligation floor
                installation quote to help you weigh your options.
              </p>

              <p>
                We pride ourselves on being the professionals {props.data.title} turns to
                when they need affordable floor installation done right. Get
                started by learning more about our services and contacting us
                below!
              </p>
            </div>
          </div>
        </div>

        <div className={styles["content-flex"]}>
          <div className={styles.left}>
            <h2>Our Floor Installation Process</h2>
            <p>
              As a full service floor installer here in {props.data.title}, we’re well
              equipped to help customers at any stage of their journey. Whether
              you already know the kind of floor you want installed or have no
              clue, we’ll start with a brief but thorough in-home consultation
              to better understand your home, what you’re looking for, and any
              requests or notes you may have. This information will allow us to
              offer you our best options for installation, including the
              material we have in stock that may fit your needs, our estimated
              timeline for work, and even an estimate based on the combination
              of flooring and manpower that will be required.
            </p>
            <p>
              Once you’ve reviewed all of your options and come to a decision,
              we’ll firm up your estimate for your approval. The estimate will
              include everything: including the costs, time of completion, and
              specifics based on your requests. Simply review it and agree, and
              we’ll get started on the work!
            </p>
            <p>
              Our process is quick and easy, and truly is designed to get you
              the most bang for your buck. We understand that there are plenty
              of flooring options in {props.data.title}, so we try to be the best one by
              tailoring our services to your convenience. Many customers
              complete the estimate process and sign off on installation in as
              little as a few hours!
            </p>

            <h3>
              Our installation expertise can save you a ton of time and money
            </h3>
            <p>
              We’ve been installing quality flooring in and around {props.data.title} for
              years, so we know a thing or two about providing value in what we
              do. Unlike big box alternatives that will send a junior installer
              with instructions to get the job done as easily as possible, we’re
              experts at what we do. This means we’ll be more efficient, won’t
              cut corners, and can guarantee you a beautiful, long lasting floor
              that won’t require attention for a very, very long time.
            </p>
            <p>
              You may not think about it now, but there’s nothing more
              inconvenient than a poorly installed floor that gives you headache
              after headache years down the road. Floor installation is a big
              investment, and we believe it pays to do it right the first time.
              Our installation expertise ensures you’ll never have to think
              twice about what’s under your feet!
            </p>
          </div>

          <div className={styles.right}>
            <h2>Why My Flooring Guy?</h2>
            <p>
              As a small, locally owned and operated company rooted right here
              in {props.data.title}, California, My Flooring Guy takes pride in hard work,
              quality craftsmanship, attentive service, and the belief that if
              you can dream it, you can do it.
            </p>
            <p>
              Unlike other brands that can cost you a fortune (or charge you
              little to nothing for subpar materials), we blend incredibly good
              products with fair, reasonable pricing to give you the best all
              around bang for your buck. And, when it comes to their homes, most
              folks wouldn’t have it any other way.
            </p>
            <p>
              We treat each one of our customers like real family, and we work
              hard to deliver the kinds of results that will encourage you to
              come back or share our service with our friends, loved ones, and
              neighbors. Our 5-star rating on Google was hard earned, and we
              strive to keep it right where it is by overdelivering and
              exceeding expectations. No matter what kind of installation you
              need, we can get it done quickly, affordably, and with the highest
              attention to detail. We’ve been the team {props.data.title} can trust for all
              of their flooring installation needs for years, and we don’t
              intend to stop being the city’s go-to. When you choose us for your
              flooring installation, you won’t be disappointed.
            </p>

            <h3>
              Get started with the best floor installation team in {props.data.title}
              today!
            </h3>
            <p>
              Your home is your castle, so when you want a new floor, it pays to
              put the best team in the business on the job. The team at My
              Flooring Guy has years of experience installing floors of all
              kinds across {props.data.title} and the rest of California. We don’t believe
              installing floors should be difficult, time consuming, or
              expensive– so we don’t allow it to be. We’ll get the job done
              right, the very first time, without any of the headache big box
              brands are known for giving their customers.
            </p>
            <p>
              Request an estimate or book an appointment to browse the flooring
              options in our showroom by completing the form below. If you have
              additional questions about our flooring installation process or
              what to expect, feel free to call us here, or drop by one of our
              two locations to chat with our team in person!
            </p>
          </div>
        </div>
      </div>
      <ScrollButton/>
    </div>
  );
}

export default LandingPage1;
