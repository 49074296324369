export const data = [
  {
    title: "1. Plan with our Project Manager Virtually",
    image: "pexels-4.jpg",
    summary: "Collaborate with one of our experienced project managers through our virtual platform, "
    + "where you can explore a range of flooring options and discuss your specific "
    + "requirements and design preferences in detail. Our project manager will guide you "
    + "through the entire process, from creating a customized flooring plan to generating a "
    + "comprehensive estimate that outlines all of the necessary steps and associated costs, "
    + "ensuring that you receive a tailored and exceptional flooring solution that perfectly "
    + "meets your needs and exceeds your expectations."
  },
  {
    title: "2. Refine all the Details",
    subtitle:
      "Your dedicated project planner will teach you everything you need to know about renovating and set your project up for success.",
    image: "pexels-24.jpg",
    summary: "Our field operator will conduct an on-site visit to gather precise measurements and provide "
    + "an array of flooring samples."
    + " In addition, our Field Operator will work closely with "
    + "you to understand your specific needs and preferences, tailoring our estimate to capture "
    + "all of the important details and ensuring that we are able to deliver a truly personalized "
    + "and exceptional flooring solution for your project."
  },
  {
    title: "3. Build with the Best",
    image: "pexels-42.jpg",
    summary: "Our highly skilled team of flooring specialists possess the expertise and experience "
    + "required to deliver an outstanding installation or refinishing service, designed to bring "
    + "your vision for your ideal floors to life. With a meticulous attention to detail, we strive to "
    + "ensure that every element of your flooring installation or refinishing project is executed "
    + "to the highest standards of quality, professionalism and craftsmanship, leaving you with "
    + "a stunning, durable and functional space that you can enjoy for years to come."
  },
];
