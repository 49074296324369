import styles from "./Hardwood.module.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Hardwood() {
  document.title = "My Flooring Guy Now | Hardwood"

  const navigate = useNavigate();

  const faq = [
    {
      q: "Are hardwood floors better than other materials?",
      a:
        "Hardwood floors are natural, and can last for a very long time. Many folks see this as being a premium quality. However, there’s no such thing as a “better” type of floor. It’s all up to you!",
    },
    {
      q: "Are hardwood floors expensive?",
      a:
        "At My Flooring Guy, our goal is to provide the highest quality products at the lowest cost to our customers. Most of our customers find the quality and price of our hardwood floors to be a perfect fit!",
    },
    {
      q: "Do you offer all types of hardwood?",
      a:
        "We carry the standard types of hardwood, as well as a selection of specialty types that our in-store specialists can introduce to you!",
    },
  ];
  return (
    <div className={styles["products-container"]}>
      <div className={styles.hardwood}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className={styles["content-block"]}>
            <div className={styles.content}>
              <h2>About Hardwood</h2>

              <p>
                Hardwood flooring is a popular choice for homeowners and
                designers alike, thanks to its timeless beauty, durability, and
                versatility. Hardwood floors add warmth and character to any
                room and complement a variety of decor styles, from traditional
                to modern.
              </p>

              <p>
                One of the biggest advantages of hardwood flooring is its
                durability. Hardwood floors can last for decades with proper
                care and maintenance, making them a valuable investment for your
                home. They are highly resistant to scratches, dents, and stains,
                and can be refinished multiple times to restore their original
                beauty.
              </p>

              <p>
                Hardwood flooring is available in a wide range of species,
                finishes, and colors, allowing you to customize the look of your
                floors to your personal style and needs. From the classic beauty
                of oak and maple to the rich, warm tones of cherry and walnut,
                there is a hardwood flooring option to suit any taste and
                budget.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-7.jpg)",
              }}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["content-block"] + " " + styles.secondary}>
            <div className={styles.content}>
              <h2>Why Hardwood?</h2>
              <p>
                Hardwood flooring comes in both Pre-finished and Unfinished
                options. Pre-finished hardwood flooring is finished in the
                factory, with the stain and topcoat applied before installation.
                This means that the flooring is ready to use immediately after
                installation, and the finish is highly durable and resistant to
                wear and tear.
              </p>
              <p>
                Unfinished hardwood flooring, on the other hand, requires
                sanding, staining, and sealing after installation. However,
                unfinished hardwood flooring allows for more customization and
                flexibility in terms of the color and finish of the floor, as
                well as the ability to match existing floors in the home.
              </p>
              <p>
                Ultimately, the choice between pre-finished and unfinished
                hardwood flooring depends on your personal preferences and
                needs. Pre-finished hardwood flooring is a convenient and
                durable option, while unfinished hardwood flooring allows for
                more customization and flexibility in achieving the desired look
                and feel of the floor.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-8.jpg)",
              }}
            />
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ y: 80 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ amount: 0.4, once: true }}
      >
        <center>
          <button
            className={styles["bottom-button"]}
            onClick={() => navigate("/get-estimate")}
          >
            Get Your Estimate
          </button>
        </center>
      </motion.div>

      <div className={styles.faq}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["faq-container"]}>
            <h1>Commonly Asked Questions</h1>
            {faq.map((item, index) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                key={index}
                sx={{
                  width: "100%",
                  backgroundColor: "#474747",
                  borderTop: "1px dashed #f6f3ed",
                  borderBottom: index + 1 == faq.length && "1px dashed #f6f3ed",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <FontAwesomeIcon
                      style={{ color: "#f6f3ed" }}
                      icon={faCaretDown}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: "#f6f3ed", fontSize: "18px" }}
                >
                  {item.q}
                </AccordionSummary>
                <AccordionDetails sx={{ color: "#f6f3ed" }}>
                  {item.a}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Hardwood;
