export const installationQuestions = [
    {
        q: "What type of material are you looking to install?",
        stateToSet: "Material-Type",
        list: [
        {
          value: "Vinyl/SPC",
          label: "Vinyl/SPC",
        },
        {
          value: "Laminate",
          label: "Laminate",
        },
        {
          value: "Solid Hardwood",
          label: "Solid Hardwood",
        },
        {
          value: "Engineered Hardwood",
          label: "Engineered Hardwood",
        },
        {
          value: "Not Sure",
          label: "Not Sure, Let's Talk!",
        },
      ]},
  
    {
      q: "How many square feet are you looking to install?",
      stateToSet: "Square-Feet",
      list: [
      {
        value: "100-250",
        label: "100-250",
      },
      {
        value: "250-500",
        label: "250-500",
      },
      {
        value: "500-750",
        label: "500-750",
      },
      {
        value: "750-1200",
        label: "750-1200",
      },
      {
        value: "1200+",
        label: "1200+",
      },
    ]},
  
    {
        q: "What type of flooring do you currently have?",
        stateToSet: "Flooring-Type",
        list: [
        {
          value: "Laminate/Vinyl",
          label: "Laminate/Vinyl",
        },
        {
          value: "Hardwood",
          label: "Hardwood",
        },
        {
          value: "Carpet",
          label: "Carpet",
        },
        {
          value: "Tile",
          label: "Tile",
        },
        {
          value: "Concrete",
          label: "Concrete",
        },
      ]},

    {
        q: "Have you already purchased the material?",
        stateToSet: "Material-Purchased",
        list: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
      ]},

      {
        q: "Would you be interested in seeing our material selection?",
        stateToSet: "Material-Selection",
        list: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
      ]},

      {
        q: "When are you looking to start?",
        stateToSet: "Start-Date",
        list: [
        {
          value: "1-2 Weeks",
          label: "1-2 Weeks",
        },
        {
          value: "Month",
          label: "More than a month",
        },
      ]},
  ];
  