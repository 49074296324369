import styles from "./Ratings.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Ratings() {
  return (
    <div className={styles.ratings}>
      <h1>Featured Reviews</h1>
      <h4>See why people all over love our services.</h4>
      <div className={styles.ratingsFlex}>
        <div className={styles.flexColumn}>
          <img src="google.svg" />
          <div>
            {Array.from(Array(5).keys()).map((item, index) => (
              <FontAwesomeIcon key={index} className={styles.star} icon={faStar} />
            ))}
          </div>
        </div>
        <div className={styles.flexColumn}>
          <img src="yelp.svg" />
          <div>
            {Array.from(Array(5).keys()).map((item, index) => (
              <FontAwesomeIcon key={index} className={styles.star} icon={faStar} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ratings;
