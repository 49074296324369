import styles from "./Submission.module.scss";
import { db } from "../../firebase";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { collection, addDoc, getDoc, doc, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import { Bars } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./ConfirmDialog";

function Submission(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [confirm, setConfirm] = useState(false);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [popupSrc, setPopupSrc] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const submission = (await getDoc(doc(db, "submissions", id))).data();
      // console.log(submission);
      setData(submission);

      if (submission.email && submission.inspirationURL) {
        const storage = getStorage();
        submission.inspirationURL.forEach((element) => {
          getDownloadURL(
            ref(storage, "/images/" + submission.email + "/" + element)
          ).then((imageUrl) => {
            setData((submission) => {
              return {
                ...submission,
                inspiration: submission.inspiration
                  ? [...submission.inspiration, imageUrl]
                  : [imageUrl],
              };
            });

            // console.log(imageUrl)
            //   entry.image = imageUrl;
            //   setEntries((entries) => {
            //     return [...entries];
            //   });
          });
        });
      }

      if (submission.email && submission.spaceURL) {
        const storage = getStorage();
        submission.spaceURL.forEach((element) => {
          getDownloadURL(
            ref(storage, "/images/" + submission.email + "/" + element)
          ).then((imageUrl) => {
            setData((submission) => {
              return {
                ...submission,
                space: submission.space
                  ? [...submission.space, imageUrl]
                  : [imageUrl],
              };
            });
          });
        });
      }
    };
    getData();
    setLoading(false);
    // !loading &&
    //   setData((data) => {
    //     return { ...data, inspiration: images };
    //   });
  }, []);

  function handlePopup(image) {
    setPopupSrc(image);
    setLightboxOpen(true);
  }

  async function deleteSubmission() {
    await deleteDoc(doc(db, "submissions", id)).then(() =>
      navigate("/submissions")
    );
  }

  return (
    <div className={styles["dashboard-container"]}>
      <center>
        {loading && (
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{
              display: "block !important)",
            }}
            wrapperClass="bars"
            visible={true}
          />
        )}
      </center>

      {!loading && (
        <div className={styles.submission}>
          <h2>Personal Info</h2>
          <div className={styles["info-section"]}>
            <div className={styles.item}>
              <p>{data.name}</p>
              <span>Name</span>
            </div>
            <div className={styles.item}>
              <p>{data.email}</p>
              <span>Email</span>
            </div>
            <div className={styles.item}>
              <p>{data.number}</p>
              <span>Number</span>
            </div>
          </div>
          <hr />
          <h2>General Info</h2>
          <div className={styles["info-section"]}>
            <div className={styles.item}>
              <p>{data.zipcode}</p>
              <span>Zipcode</span>
            </div>
            <div className={styles.item}>
              <p>{data.type}</p>
              <span>Type</span>
            </div>

            <div className={styles.item}>
              <p>{new Date(data.date).toLocaleString()}</p>
              <span>Date</span>
            </div>
          </div>
          <hr />

          <div className={styles.results}>
            <h2>Form Response</h2>
            {data.data &&
              data.data.length > 0 &&
              data.data.map((item, index) => {
                return (
                  <div key={index} className={styles["results-item"]}>
                    <span>
                      {item.index}) {item.question}
                    </span>
                    <p>{item.answer}</p>
                  </div>
                );
              })}
          </div>

          <Popup
            setLightboxOpen={setLightboxOpen}
            lightboxOpen={lightboxOpen}
            src={popupSrc}
          />

          <ConfirmDialog
            setLightboxOpen={setConfirm}
            lightboxOpen={confirm}
            deleteSubmission={deleteSubmission}
          />

          {data.space && data.space.length > 0 && <h2>The Space</h2>}
          <div className={styles.images}>
            {data.space &&
              data.space.length > 0 &&
              data.space.map((image, index) => (
                <div key={index} className={styles.big}>
                  <img
                    onClick={() => handlePopup(image)}
                    src={image}
                    src={image}
                  />
                </div>
              ))}
          </div>

          {data.inspiration && data.inspiration.length && <h2>Inspiration</h2>}
          <div className={styles.images}>
            {data.inspiration &&
              data.inspiration.length > 0 &&
              data.inspiration.map((image, index) => (
                <div key={index} className={styles.big}>
                  <img onClick={() => handlePopup(image)} src={image} />
                </div>
              ))}
          </div>
          <div className={styles.delete}>
          <button onClick={() => setConfirm(true)}>Delete Submission</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Submission;
