import React, { useCallback } from "react";

import styles from "./Tabs.module.scss";

import { useSearchParams } from "react-router-dom";

function TabTitle(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const param = props.param

  var title = props.title;
  var setSelectedTab = props.setSelectedTab;
  const onClick = useCallback(() => {
    setSelectedTab(title);
    // console.log(title);
    param && setSearchParams({ [param]: title });
  }, [setSelectedTab, setSearchParams, title]);

  return (
    <li className={styles["tab-item"]}>
      <button
        className={
          styles["tab-button"] +
          " " +
          styles[props.class] +
          " " +
          styles[props.page]
        }
        onClick={onClick}
      >
        {title}
      </button>
    </li>
  );
}

export default TabTitle;
