import styles from "./LandingPage1Form.module.scss";
import TextBox from "./../common/TextBox";
import { useState } from "react";
import { db } from "./../../firebase";
import { collection, addDoc } from "firebase/firestore";

function LandingPage1Form() {
  const [url, setURL] = useState("");
  const [city, setCity] = useState("");

  async function handleSubmit() {
    await addDoc(collection(db, "landing-pages"), {
        title: city,
        url: url,
        type: "Landing Page 1"
    });
  }

  const checkDisabled = url === "" && city === "" ? true : false;

  return (
    <div className={styles["form-container"]}>
      <div className={styles.form}>
        <h1>Enter Landing Page Info</h1>
        <TextBox
          placeholder={"Enter Desired URL"}
          label="URL"
          value={url}
          onChange={(event) => setURL(event.target.value)}
          onBlur={() => {}}
        />

        <TextBox
          placeholder={"Enter Desired City"}
          label="City"
          value={city}
          onChange={(event) => setCity(event.target.value)}
          onBlur={() => {}}
        />
        <br/>
        
        <center><button disabled={checkDisabled} onClick={handleSubmit}>Submit Form</button></center>
      </div>
    </div>
  );
}

export default LandingPage1Form;
