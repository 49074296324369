import BurgerMenu from "../BurgerMenu";
import styles from "./Header.module.scss";
import { useEffect, useState } from "react";
import { logout } from "./../../firebase";
import { NavLink, Link, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faUser } from "@fortawesome/free-solid-svg-icons";

function Header(props) {
  let location = useLocation();

  let activeStyle = {
    textDecoration: "underline",
  };

  const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
    () => ({
      "& .MuiPaper-root": {
        borderRadius: 6,
        minWidth: 180,
        backgroundColor: "#f6f3ed",

        boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
          padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
          "& .MuiSvgIcon-root": {
            fontSize: 18,
          },
          "&:active": {},
        },
      },
    })
  );

  // const [activeUser, setActiveUser] = useState(props.user);
  useEffect(() => {
    function resizeHeaderOnScroll() {
      // console.log("Running");
      const distanceY =
          window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 80,
        headerEl = document.querySelector("header");

      if (distanceY > shrinkOn) {
        headerEl.style.background = "rgba(246, 243, 237, 1)";
        headerEl.style.boxShadow = "0px 5px 2px -2px rgb(0 0 0 / 15%)";
      } else {
        headerEl.style.background = "rgba(246, 243, 237, 0.0)";
        headerEl.style.boxShadow = "";
      }
    }

    if (location.pathname === "/") {
      // Update the document title using the browser API
      const distanceY =
          window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 80,
        headerEl = document.querySelector("header");

      if (distanceY > shrinkOn) {
        headerEl.style.background = "rgba(246, 243, 237, 1)";
        headerEl.style.boxShadow = "0px 5px 2px -2px rgb(0 0 0 / 15%)";
      } else {
        headerEl.style.background = "rgba(246, 243, 237, 0.0)";
        headerEl.style.boxShadow = "";
      }
      window.addEventListener("scroll", resizeHeaderOnScroll);
      return () => window.removeEventListener("scroll", resizeHeaderOnScroll);
    } else {
      const headerEl = document.querySelector("header");
      // location.pathname !== "/" &&
      headerEl.style.removeProperty("background");
      headerEl.style.removeProperty("boxShadow");
    }
  }, [location.pathname]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      className={`${styles["header-main"]} ${
        location.pathname === "/" ? "" : styles.white
      }`}
    >
      <div className={styles["header-second-level-desktop"]}>
        <Link className={styles.logo} to="/">
          <img
            alt="Company logo"
            src={process.env.PUBLIC_URL + "/main-logo.png"}
          />
        </Link>
        <div className={styles["nav-menu"]}>
          <div className={styles["nav-item"]}>
            <NavLink
              to="how-it-works"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>How It Works</span>
            </NavLink>
          </div>

          <div className={styles["nav-item"]}>
            <NavLink
              to="products"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Products</span>
            </NavLink>
          </div>

          <div className={styles["nav-item"]}>
            <NavLink
              to="services"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Services</span>
            </NavLink>
          </div>

          <div className={styles["nav-item"]}>
            <NavLink
              to="gallery"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <span>Gallery</span>
            </NavLink>
          </div>
        </div>

        <div className={styles["header-right"]}>
          {props.loading && <></>}

          {!props.loading &&
            props.user &&
            (props.user.email === process.env.REACT_APP_ADMIN1 || props.user.email === process.env.REACT_APP_ADMIN2) && (
              <div className={styles["nav-item"]}>
                <span onClick={handleClick}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: "10px" }}
                  />
                  <FontAwesomeIcon
                    className={
                      styles["caret-rotate"] +
                      " " +
                      (open === true && styles.open)
                    }
                    icon={faCaretDown}
                  />
                </span>

                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  onMouseLeave={handleClose}
                >
                  <div className={styles["pop-nav-item"]}>
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="submissions"
                      onClick={() => handleClose("/admin/submissions")}
                    >
                      Submissions
                    </NavLink>
                  </div>
                  <div className={styles["pop-nav-item"]}>
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="create-blog"
                      onClick={() => handleClose("/create-blog")}
                    >
                      Create Blog
                    </NavLink>
                  </div>
                  <div className={styles["pop-nav-item"]}>
                    <NavLink
                      onClick={() => {
                        logout();
                        handleClose();
                      }}
                    >
                      Log Out
                    </NavLink>
                  </div>
                </StyledMenu>
              </div>
            )}

          {/* {!props.loading &&
            props.user &&
            props.user.uid !== process.env.REACT_APP_ADMIN && (
              <div className={styles["nav-item"]}>
                <span onClick={handleClick}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: "10px" }}
                  />
                  <FontAwesomeIcon
                    className={
                      styles["caret-rotate"] +
                      " " +
                      (open === true && styles.open)
                    }
                    icon={faCaretDown}
                  />
                </span>

                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  onMouseLeave={handleClose}
                >
                  <div className={styles["pop-nav-item"]}>
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="account"
                      onClick={() => handleClose("/account")}
                    >
                      My Account
                    </NavLink>
                  </div>

                  <div className={styles["pop-nav-item"]}>
                    <NavLink
                      onClick={() => {
                        logout();
                        handleClose();
                      }}
                    >
                      Log Out
                    </NavLink>
                  </div>
                </StyledMenu>
              </div>
            )} */}

          <Link className={styles["try-btn"]} to="get-estimate">
            Get Your Estimate{" "}
          </Link>
        </div>
      </div>

      <div className={styles["header-second-level-mobile"]}>
        <BurgerMenu user={props.user}/>
        <Link className={styles.logo} to="/">
          <img
            alt="Company logo"
            src={process.env.PUBLIC_URL + "/main-logo.png"}
          />
        </Link>

        <div className={styles["header-right"]}>
          <Link className={styles["try-btn"]} to="get-estimate">
            Get Your Estimate{" "}
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
