import styles from "./HowItWorks.module.scss";
import { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ServicesTemplate from "./ServicesTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { installationData, refinishData, repairData } from "./ServicesData";

function Services(props) {
  document.title = "My Flooring Guy Now | Services";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const navigate = useNavigate();
  const ref = useRef(null);

  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const q = params.get("Services");

  const validQuery = ["Installation", "Refinish", "Repairs"];

  //   if(!validQuery.includes(q)){
  //       return ""
  //   }

  const [selectedTab, setSelectedTab] = useState(
    props.service ? props.service : "Installation"
  );

  const scrollToDiv = () => {
    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  function toggleActive(name) {
    setSelectedTab(name);
  }

  return (
    <section className={styles["hiw-section"]}>
      <div>
        <div className={styles["hiw-main"]}>
          <div className={styles["hiw-top"]}>
            <h2>Services</h2>
          </div>
          <div className={styles["hiw-bottom"] + " " + "scroll-div-target"}>
            <div
              onClick={() => {
                toggleActive("Installation");
                navigate("./../floor-installation");
                scrollToDiv();
              }}
              className={selectedTab == "Installation" && styles["active"]}
            >
              <span className={styles.text}>Installation</span>
            </div>
            <div
              onClick={() => {
                toggleActive("Refinish");
                navigate("./../hardwood-floor-refinish");
                scrollToDiv();
              }}
              className={selectedTab == "Refinish" && styles["active"]}
            >
              <span className={styles.text}>Refinish</span>
            </div>
            <div
              onClick={() => {
                toggleActive("Repair");
                navigate("./../hardwood-floor-repair");
                scrollToDiv();
              }}
              className={selectedTab == "Repair" && styles["active"]}
            >
              <span className={styles.text}>Repair</span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ scrollMarginTop: "180px" }} ref={ref}>
        {selectedTab === "Installation" && (
          <ServicesTemplate data={installationData} selectedTab={selectedTab} />
        )}
        {selectedTab === "Refinish" && (
          <ServicesTemplate data={refinishData} selectedTab={selectedTab} />
        )}
        {selectedTab === "Repair" && (
          <ServicesTemplate data={repairData} selectedTab={selectedTab} />
        )}
      </div>
    </section>
  );
}

export default Services;
