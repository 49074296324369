import styles from "./Blogs.module.scss";
import Blog from "./Blog";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Pagination } from "@mui/material";

function Blogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState(null);
  const [page, setPage] = useState(0);
  const itemsPerPage = useMemo(() => 2);

  useEffect(() => {
    const getData = async () => {
      let blogsArray = [];
      const blogsData = await getDocs(collection(db, "blogs"));
      blogsData.forEach((blog) =>
        blogsArray.push({ ...blog.data(), id: blog.id })
      );
      blogsArray.sort((a, b) => b.date - a.date);
      setBlogs(blogsArray);
    };
    getData();
  }, []);

  const handleChange = (event, value) => {
    setPage(value - 1);
  };

  return (
    blogs && (
      <div className={styles.container}>
        <h1>Blog</h1>
        {blogs
          .slice(itemsPerPage * page, itemsPerPage * page + itemsPerPage)
          .map((item, index) => {
            return (
              <Blog
                key={index}
                title={item.title}
                description={item.description}
                date={item.date}
                viewDetails={() => navigate("/blog/" + item.id)}
              />
            );
          })}
        <Pagination
          count={Math.ceil(blogs.length / itemsPerPage)}
          page={page + 1}
          onChange={handleChange}
          sx={{ justifyContent: "center", display: "flex" }}
        />
      </div>
    )
  );
}

export default Blogs;
