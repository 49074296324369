import { useState, useEffect, useRef } from "react";
import "./Dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

function Dropdown(props) {
  const [isActive, setIsActive] = useState(false);
  const handleSort = props.handleSort;
  const selected = props.selected;

  const node = useRef();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };
  
  useOnClickOutside(node, () => setIsActive(false));

  return (
    <div className="dropdown" ref={node}>
      <div
        onClick={(e) => {
          setIsActive(!isActive);
        }}
        className="dropdown-btn"
      >
        <div className={StyleSheet.selected}>{selected}</div>
        <div>
          <FontAwesomeIcon icon={isActive ? faCaretUp : faCaretDown} />
        </div>
      </div>
      <div
        className={"dropdown-content"}
        style={{ display: isActive ? "block" : "none" }}
      >
        <div
          className="item"
          onClick={(e) => {
            handleSort(e.target.textContent);
            setIsActive(!isActive);
          }}
        >
          Date (desc)
        </div>
        <div
          className="item"
          onClick={(e) => {
            handleSort(e.target.textContent);
            setIsActive(!isActive);
          }}
        >
          Date (asc)
        </div>
        <div
          onClick={(e) => {
            handleSort(e.target.textContent);
            setIsActive(!isActive);
          }}
          className="item"
        >
          Name (desc)
        </div>
        <div
          className="item"
          onClick={(e) => {
            handleSort(e.target.textContent);
            setIsActive(!isActive);
          }}
        >
          Name (asc)
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
