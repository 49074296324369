import styles from "./LandingPageTemplates.module.scss";
import { useNavigate } from "react-router-dom";

function LandingPageTemplates(props) {
  const navigate = useNavigate();

  return (
    <div className={styles["landing-page-main"]}>
      <div className={styles.item}>
        <h2>Landing Page 1</h2>
        <img
          onClick={() => navigate("../landing-page-1")}
          src={process.env.PUBLIC_URL + "/LandingPage1.png"}
        />
        <br />
        <button onClick={() => navigate("../landing-page-1")}>
          Add Landing Page
        </button>
      </div>
    </div>
  );
}

export default LandingPageTemplates;
