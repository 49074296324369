import Slider from "react-slick";
import styles from "./Reviews.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Reviews() {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25%",
    infinite: true,
    responsive: [
      { breakpoint: 592, settings: { centerMode: false, centerPadding: 0 } },
    ],
  };

  const reviewArray = [
    {
      id: 1,
      name: "Kate F.",
      stars: 5,
      review:
        "They did an absolutely amazing job! They were on time, efficient and the result was beautiful! Will definitely hire them again for any of my future projects! The install was done perfectly and I love how the floors turned out. I strongly recommend them for any flooring projects!",
    },
    {
      id: 2,
      name: "Michael L.",
      stars: 5,
      review: "The service and quality we had from working with Jacob and our installation crew was top notch! Jacob was very informative and made sure we had all of our questions answered. He also ensured that we received the best price for the installation and materials. The installers worked very quickly (they were done in less than a full day!) And still managed to deliver top notch quality in their work. We highly recommend going with this company for your flooring needs!",
    },
    {
      id: 3,
      name: "Chris C.",
      stars: 5,
      review: "Amit was awesome, showed up and gave me the breakdown of materials and labor with recomendations on what type of floor I should put down in my garage rec room. 2 days later they put in over 200 sq ft of floor in less than 2 hours, which acceeded expectations as I had a meeting in downtown at noon, 2 hours before I had to leave. I plan on using again for my kitchen next year!",
    },
  ];
  return (
    <div>
      <Slider {...settings} className={styles.slick}>
        {reviewArray.map((item) => (
          <div key={item.id} className={styles["slider-div"]}>
            <div>
              <p>{item.name}</p>
              <br />
              {Array.from(Array(item.stars).keys()).map((item, index) => (
                <FontAwesomeIcon
                  key={index}
                  className={styles.star}
                  icon={faStar}
                />
              ))}

              <br />
              <br />
              <span className={styles.review}>{item.review}</span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Reviews;
