import TextBox from "../common/TextBox";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";

function EstimateSelect(props) {
  const [isActive, setIsActive] = useState(false);

  const node = useRef();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(node, () => setIsActive(false));

  return (
    <div className="dropdown-form" ref={node}>
      <div
        onClick={(e) => {
          setIsActive(!isActive);
        }}
        className="dropdown-btn"
      >
        <div className={StyleSheet.selected}>
          {props.values[props.stateToSet] || props.question}
        </div>
        <div>
          <FontAwesomeIcon icon={isActive ? faCaretUp : faCaretDown} />
        </div>
      </div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={"dropdown-content"}
          >
            {/* <div
        className={"dropdown-content"}
        // style={{ display: isActive ? "block" : "none" }}
      > */}

            {props.options.map((option, index) => (
              <div
                className="item"
                onClick={(event) => {
                  props.setValues((values) => {
                    return {
                      ...values,
                      [props.stateToSet]: option.value,
                    };
                  });
                  setIsActive(!isActive);
                }}
                key={index}
              >
                {option.label}
              </div>
            ))}

            {/* </div> */}
          </motion.div>
        )}
      </AnimatePresence>

      {props.values["Square-Feet"] === "Not Sure" &&
        props.stateToSet == "Square-Feet" && (
          <>
            <br />
            <TextBox
              placeholder="Enter The Number of Rooms"
              label="If so, enter the amount of rooms"
              onChange={(event) => {
                props.handleChange(event, "Square-Feet-Rooms");
              }}
              value={props.values["Square-Feet-Rooms"] || ""}
              type="text"
              onBlur={(event) => {}}
              blurIncluded={false}
            />
          </>
        )}
    </div>
  );
}

export default EstimateSelect;
