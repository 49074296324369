import { useState } from "react";
import styles from "./../../styles/Steps.module.scss";
import { useCalendlyEventListener, PopupModal } from "react-calendly";

function SubmissionPage(props) {
  const [isOpen, setOpen] = useState(true);

  // useCalendlyEventListener({
  //   onProfilePageViewed: () => console.log("onProfilePageViewed"),
  //   onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
  //   onEventTypeViewed: () => console.log("onEventTypeViewed"),
  //   onEventScheduled: (e) => console.log(e.data.payload.event.uri),
  // });

  return (
    <div
      className={
        props.direction === "forward"
          ? styles[`steps-container-forward`]
          : styles[`steps-container-back`]
      }
    >
      <div className={styles.submission}>
        <h1>Thank you for reaching out!</h1>
        <p>
          Now, schedule your virtual estimate!
        </p>
        <button onClick={() => setOpen(true)}>
          Click Here To Schedule Your Virtual Estimate
        </button>
      </div>
      {isOpen && (
        <PopupModal
          url="https://calendly.com/myflooringguynow"
          //  pageSettings={this.props.pageSettings}
          //  utm={this.props.utm}
          //  prefill={this.props.prefill}
          onModalClose={() => setOpen(false)}
          open={isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      )}
    </div>
  );
}

export default SubmissionPage;
