import { useState, useEffect } from "react";
import styles from "./../../styles/Steps.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import RadioButton from "./RadioButton";
import { installationMaterialQuestions } from "./Installation-MaterialQuestions";
import { refinishQuestions } from "./RefinishQuestions";
import { installationQuestions } from "./InstallationQuestions";
import { repairQuestions } from "./RepairQuestions";
import EstimateSelect from "./EstimateSelect";

function SecondStep(props) {
  const handleChange = props.handleChange;

  // const [isValidated, setValidated] = useState(false);
  const [questions, setQuestions] = useState(refinishQuestions);
  // const [materials, setMaterials] = useState(false);

  useEffect(() => {
    if (props.type == "Refinish") {
      setQuestions(refinishQuestions);
    } else if (props.type == "Installation/Material") {
      setQuestions(installationMaterialQuestions);
    } else if (props.type == "Installation Only") {
      setQuestions(installationQuestions);
    } else if (props.type == "Repair") {
      setQuestions(repairQuestions);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // function checkAgain(value) {
  //   if (value["material-type"]) {
  //     setValidated(true);
  //   }
  //   else{
  //     setValidated(false)
  //   }
  // }
  // if(questions.length != 0){

  // }

  const isDisabled = questions
    .map((current) => {
      if (props.values[current?.stateToSet]) {
        return true;
      } else {
        return false;
      }
    })
    .reduce((prev, current) => prev && current);

  return (
    <div
      className={
        props.direction == "forward"
          ? styles[`steps-container-forward`]
          : styles[`steps-container-back`]
      }
    >
      <div className={styles["button-div"]}>
        <button style={{ float: "left" }} onClick={props.back}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>

        <button
          style={{ float: "right" }}
          disabled={!isDisabled}
          onClick={props.next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <div className={styles.questions}>
        {questions.map((question, item) => {
          return (
            <div key={item} className={styles["question-item"]}>
              {/* <h2>{question.q}</h2> */}

              {/* {question.list.map((item, num) => {
                return (
                  <RadioButton
                    key={num}
                    value={item.value}
                    handleChange={handleChange}
                    values={props.values}
                    stateToSet={question.stateToSet}
                    label={item.label}
                  />
                );
              })} */}

             
                  <EstimateSelect
                    handleChange={handleChange}
                    values={props.values}
                    stateToSet={question.stateToSet}
                    options={question.list}
                    setValues={props.setValues}
                    question={question.q}
                  />
               
              
            </div>
          );
        })}
      </div>

      <div className={styles["button-div"]}>
        <button style={{ float: "left" }} onClick={props.back}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>

        <button
          style={{ float: "right" }}
          disabled={!isDisabled}
          onClick={props.next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
}
export default SecondStep;
