import styles from "./Hardwood.module.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Laminate() {
  document.title = "My Flooring Guy Now | Laminate"

  const navigate = useNavigate();

  const faq = [
    {
      q: "Are hardwood floors better than other materials?",
      a:
        "Hardwood floors are natural, and can last for a very long time. Many folks see this as being a premium quality. However, there’s no such thing as a “better” type of floor. It’s all up to you!",
    },
    {
      q: "Are hardwood floors expensive?",
      a:
        "At My Flooring Guy, our goal is to provide the highest quality products at the lowest cost to our customers. Most of our customers find the quality and price of our hardwood floors to be a perfect fit!",
    },
    {
      q: "Do you offer all types of hardwood?",
      a:
        "We carry the standard types of hardwood, as well as a selection of specialty types that our in-store specialists can introduce to you!",
    },
  ];
  return (
    <div className={styles["products-container"]}>
      <div className={styles.hardwood}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className={styles["content-block"]}>
            <div className={styles.content}>
              <h2>About Laminate</h2>

              <p>
                If you are considering a new flooring option for your home or
                business, laminate flooring is great choice! One of the key
                reasons to install laminate flooring is its affordability.
                Compared to other flooring options such as hardwood or tile,
                laminate flooring is often less expensive, making it a
                budget-friendly option for those who want a stylish and durable
                flooring solution without breaking the bank.
              </p>
              <p>
                In addition to its affordability, laminate flooring is also
                highly durable. Its wear layer is designed to withstand
                scratches, dents, and stains, making it ideal for high-traffic
                areas and busy households. This means that it requires minimal
                maintenance, which is a big plus for many homeowners and
                businesses.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-11.jpg)",
              }}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["content-block"] + " " + styles.secondary}>
            <div className={styles.content}>
              <h2>Why Laminate?</h2>
              <p>
                Overall, laminate flooring is a versatile and practical flooring
                option that offers a range of benefits for homeowners and
                businesses alike. Whether you are looking for a budget- friendly
                and low-maintenance flooring solution, or a durable and stylish
                option for your home or office, laminate flooring is definitely
                worth considering.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-10.jpg)",
              }}
            />
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ y: 80 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ amount: 0.4, once: true }}
      >
        <center>
          <button
            className={styles["bottom-button"]}
            onClick={() => navigate("/get-estimate")}
          >
            Get Your Estimate
          </button>
        </center>
      </motion.div>

      <div className={styles.faq}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["faq-container"]}>
            <h1>Commonly Asked Questions</h1>
            {faq.map((item, index) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                key={index}
                sx={{
                  width: "100%",
                  backgroundColor: "#474747",
                  borderTop: "1px dashed #f6f3ed",
                  borderBottom: index + 1 == faq.length && "1px dashed #f6f3ed",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <FontAwesomeIcon
                      style={{ color: "#f6f3ed" }}
                      icon={faCaretDown}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: "#f6f3ed" }}
                >
                  {item.q}
                </AccordionSummary>
                <AccordionDetails sx={{ color: "#f6f3ed" }}>
                  {item.a}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Laminate;
