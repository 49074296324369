export const typeQuestions = [
  {
    stateToSet: "type",
    list: [
      {
        value: "Installation/Material",
        label: "Installation & Material",
      },
      {
        value: "Installation Only",
        label: "Installation Only",
      },
      {
        value: "Refinish",
        label: "Refinish",
      },
      {
        value: "Repair",
        label: "Repair",
      },
    ],
  },
];
