import styles from "./Products.module.scss";
import style from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useEffect } from "react";

function Products() {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25%",
    infinite: true,
    responsive: [
      { breakpoint: 808, settings: { centerMode: true, centerPadding: "15%" } },
    ],
  };

  return (
    <section className={styles.services}>
      <div className={styles["services-second"]}>
        <div className={styles.carosel}>
          <Slider {...settings} className={styles.slick}>
            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>Hardwood</h2>

                <img src="/images/pexels-25.jpg" />

                <p>
                  Hardwood flooring is a timeless and elegant choice that adds
                  warmth and natural beauty to your space
                </p>
                <button onClick={() => navigate("products&Products=Hardwood")}>
                  View More
                </button>
              </div>
            </div>

            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>Laminate</h2>
                <img src="/images/pexels-26.jpg" />
                <p>
                  Laminate flooring is a popular and cost-effective type of
                  flooring that offers is a great choice for high-traffic areas
                  in your home.
                </p>
                <button onClick={() => navigate("products&Products=Laminate")}>
                  View More
                </button>
              </div>
            </div>

            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>Vinyl</h2>

                <img src="/images/pexels-27.jpg" />

                <p>
                  A combination of affordability, durability, and aesthetic
                  versatility, vinyl flooring is a great choice to create a
                  stylish and functional space that will stand the test of time.
                </p>
                <button onClick={() => navigate("products&Products=Vinyl")}>
                  View More
                </button>
              </div>
            </div>

            <div className={styles["slider-div"]}>
              <div className={styles["slider-div-2"]}>
                <h2>SPC</h2>
                <img src="/images/pexels-28.jpg" />
                <p>
                  SPC flooring is highly durable, waterproof, resistant to
                  scratches and stains, and available in a variety of styles and
                  colors to match any decor
                </p>
                <button onClick={() => navigate("products&Products=SPC")}>
                  View More
                </button>
              </div>
            </div>
          </Slider>
        </div>

        <div className={styles["services-grid"]}>
          <div className={styles["services-grid-item"]}>
            <h2>Hardwood</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-25.jpg" />
              </span>
            </div>

            <p>
              Hardwood flooring is a timeless and elegant choice that adds
              warmth and natural beauty to your space
            </p>

            <button onClick={() => navigate("/products/hardwood")}>
              View More
            </button>
          </div>

          <div className={styles["services-grid-item"]}>
            <h2>Laminate</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-26.jpg" />
              </span>
            </div>

            <p>
              Laminate flooring is a popular and cost-effective type of flooring
              that offers is a great choice for high-traffic areas in your home.
            </p>

            <button onClick={() => navigate("/products/laminate")}>
              View More
            </button>
          </div>

          <div className={styles["services-grid-item"]}>
            <h2>Vinyl</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-27.jpg" />
              </span>
            </div>

            <p>
              A combination of affordability, durability, and aesthetic
              versatility, vinyl flooring is a great choice to create a stylish
              and functional space that will stand the test of time.
            </p>

            <button onClick={() => navigate("/products/vinyl")}>
              View More
            </button>
          </div>

          <div className={styles["services-grid-item"]}>
            <h2>SPC</h2>
            <div className={style["grid-image-container"]}>
              <span>
                <span></span>
                <img src="/images/pexels-28.jpg" />
              </span>
            </div>

            <p>
              SPC flooring is highly durable, waterproof, resistant to scratches
              and stains, and available in a variety of styles and colors to
              match any decor
            </p>

            <button onClick={() => navigate("/products/spc")}>View More</button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Products;
