import "./../../styles/TextBox.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

function TextBox(props) {
  // add active class and placeholder
  const [isFocused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true)
    const target = e.target;
    props.error && target.parentNode.classList.remove("error");
    target.parentNode.classList.add("active");
    target.setAttribute("placeholder", target.getAttribute("data-placeholder"));
  };

  // remove active class and placeholder
  const handleBlur = (e) => {
    setFocused(false)
    const error = props.onBlur(e);
    const target = e.target;
    error && target.parentNode.classList.add("error");
    if (target.value == "") {
      target.parentNode.classList.remove("active");
    }
    target.removeAttribute("placeholder");
  };

  // get DOM elements
  const floatContainers = document.querySelectorAll(".textbox-container");

  floatContainers.forEach((element) => {
    if (element.querySelector("input").value != "") {
      element.classList.add("active");
    }
    if(props.error == true && !props.blurIncluded){
      element.classList.add("error")
    }
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className={"textbox-container" + (props.value != "" ? " active" : "") + (isFocused == true ? " active" : "") }>
      <label htmlFor="floatField">{props.label}</label>
      <input
        onChange={props.onChange}
        value={props.value}
        type={props.type == "password" ? (passwordShown ? "text" : "password" ) : (props.type)}
        data-placeholder={props.placeholder}
        onBlur={handleBlur}
        onFocus={handleFocus}
        name="floatField"
      />
      {props.type == "password" && <FontAwesomeIcon className={"eye"} onClick={togglePasswordVisiblity} icon={passwordShown ? faEyeSlash : faEye} />}
    </div>
  );
}

export default TextBox;
