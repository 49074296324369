import React from "react";
import TabTitle from "./tabtitle.js";
import styles from "./Tabs.module.scss";

function Tabs(props) {
  const setSelectedTab = props.setSelectedTab;
  const selectedTab = props.selectedTab;
  function findactive(index) {
    if (index === selectedTab) {
      return "active";
    } else {
      return "inactive";
    }
  }

  return (
    <div className={styles["tabs"] + " " + styles[props.page]} style={{ background: props.background }}>
      <ul className={styles["tabs-list"]}>
        {props.children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            class={findactive(item.props.title)}
            page={item.props.page}
            param={item.props.param}
          />
        ))}
      </ul>
    </div>
  );
}

export default Tabs;
