import { useState, useEffect } from "react";
import styles from "./Home.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { useRef } from "react";
import Services from "./Services";
import Explore from "./Explore";
import Reviews from "./Reviews";
import Ratings from "./Ratings";
import Products from "./Products";
import ScrollButton from "../common/ScrollButton";
import Tabs from "../tabs/Tabs";
import TabTitle from "./../tabs/tabtitle";
import { Link, useNavigate } from "react-router-dom";

function Home() {

  document.title = "My Flooring Guy Now | Home"

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Services");

  const homeScrollRef = useRef(null);
  function scrollFunction(ref) {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <div className={styles["home-container"]}>
      <div className={styles["main-div"]}>
        <div className={styles["main-div-center"]}>
          <h1>Your flooring experience, streamlined</h1>
          <p>
            Get your free estimate now to make your design dreams come true. All
            at the click of a button.
          </p>

          <button onClick={() => navigate("/get-estimate")}>
            Get Your Estimate
          </button>
        </div>
        <div className={styles["home-scroll"]}>
          <span onClick={() => scrollFunction(homeScrollRef)}>
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
          </span>
        </div>
      </div>

      <div ref={homeScrollRef} className={styles["home-content"]}>
        <section className={styles["fingertips-steps-container"]}>
          <div className={styles["fingertips-steps"]}>
            <div className={styles["fingertips-grid"]}>
              <h1>Innovation, at your fingertips</h1>

              <p>
                Getting your estimate has never been <b>this</b> easy. In just 3
                quick steps, your dreams can become a reality.
                <br />
                <Link to="how-it-works">Learn How It Works</Link>
              </p>
            </div>

            <div className={styles["grid-steps"]}>
              <div className={styles["grid-step"]}>
                <div className={styles["grid-image-container"]}>
                  <span>
                    <span></span>
                    <img alt="Step 1" src="images/pexels-34.jpg" />
                  </span>
                </div>
                <h2>1</h2>
                <h4>Virtual Estimate</h4>
                <p>
                  Work with one of our project managers virtually to dream up
                  your ideal flooring.
                </p>
              </div>

              <div className={styles["grid-step"]}>
                <div className={styles["grid-image-container"]}>
                  <span>
                    <span></span>
                    <img alt="Step 2" src="images/pexels-43.jpg" />
                  </span>
                </div>
                <h2>2</h2>
                <h4>Down to the Details</h4>
                <p>
                  On site visit with our Field Operator to ensure all details
                  are captured.
                </p>
              </div>

              <div className={styles["grid-step"]}>
                <div className={styles["grid-image-container"]}>
                  <span>
                    <span></span>
                    <img alt="Step 3" src="images/pexels-21.jpg" />
                  </span>
                </div>
                <h2>3</h2>
                <h4>Complete your Dream</h4>
                <p>
                  Our skilled team of flooring installers will work diligently
                  to ensure that your new floors are installed or refinished to
                  the highest standards.
                </p>
              </div>
            </div>
          </div>
        </section>

        
        <h2 className={styles["offer-h2"]}>What we offer</h2>

        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          page="home"
          background="#465142"
        >
          <TabTitle title="Services" page={"home"} />
          <TabTitle title="Products" page={"home"} />
        </Tabs>

        {selectedTab === "Products" && <Products />}
        {selectedTab === "Services" && <Services />}
        <Explore />

        <Ratings />
        <Reviews />
        <ScrollButton />
      </div>
    </div>
  );
}

export default Home;
