import styles from "./ImageBlock.module.scss";

function Imageblock(props) {

  function handleLightBoxOpen(){
    props.setIndex(props.index)
    props.openModal(true)
  }
  // This is what each image is being organzied into
  return (
    <div onClick={handleLightBoxOpen} className={styles.big}>
      <img src={props.src} className="image" />
    </div>
  );
}

export default Imageblock;
