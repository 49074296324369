import styles from "./Sidebar.module.scss";
import { useState } from "react";
import Dropdown from "./../common/Dropdown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import TextBox from "../common/TextBox";
import { styled } from '@mui/material/styles';

// import { format } from "date-fns";

function Sidebar(props) {
  const [current, setCurrent] = useState("");
  const [search, setSearch] = useState("");

  const [isSelected, setIsSelected] = useState("Date (desc)");
  const [currentEntries, setCurrentEntries] = useState(props.entries);

  function handleClick(id, event) {
    if (current != "") {
      current.classList.remove("active-item");
    }
    event.currentTarget.classList.add("active-item");
    setCurrent(event.currentTarget);

    props.setDataId(id);
  }

  function handleSort(direction) {
    setIsSelected(direction);
    if (direction == "Name (asc)") {
      setCurrentEntries(
        currentEntries.sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
      );
    } else if (direction == "Name (desc)") {
      setCurrentEntries(
        currentEntries.sort((a, b) => (a.data.name < b.data.name ? 1 : -1))
      );
    }
    // console.log(currentEntries);
  }

  return (
    <div className={styles["sidebar-main"]}>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Filter
        </AccordionSummary>
        <AccordionDetails>
          <Dropdown selected={isSelected} handleSort={handleSort} />
          <br />
          <TextBox
            onChange={(event) => setSearch(event.target.value)}
            onBlur={(event) => {}}
            value={search}
            label="Search"
            placeholder="Enter search value"
          />
        </AccordionDetails>
      </Accordion>

      {currentEntries.map((entry) => {
        return (
          <div
            onClick={(event) => handleClick(entry.id, event)}
            key={entry.id}
            className={styles["sidebar-item"]}
          >
            <span>{entry.data.name}</span>

            {/* <span>{entry.data.date}</span> */}
          </div>
        );
      })}
    </div>
  );
}

export default Sidebar;
