import { useState, useRef, useEffect } from "react";
import TextEditor from "./TextEditor";
import TextBox from "./../common/TextBox";
import TextArea from "./../common/TextArea";
import { db } from "./../../firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import styles from "./EditBlog.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import ConfirmDialog from "./ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function EditBlog() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [confirm, setConfirm] = useState(false);
  const quillObj = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const submission = (await getDoc(doc(db, "blogs", id))).data();
      setEditorValue(submission.data);
      setTitle(submission.title);
      setDescription(submission.description);
    };
    getData();
  }, [id]);

  async function handleSubmit() {
    const ref = doc(db, "blogs", id);
    updateDoc(ref, {
      title: title,
      description: description,
      data: quillObj.current.getEditor().root.innerHTML,
    }).then(() => navigate("./../../" + id));
  }

  async function deleteSubmission() {
    await deleteDoc(doc(db, "blogs", id)).then(() => navigate("/blog"));
  }

  return title && description && editorValue ? (
    <div className={styles.container}>
      <ConfirmDialog
        setLightboxOpen={setConfirm}
        lightboxOpen={confirm}
        deleteSubmission={deleteSubmission}
      />
      <h1 style={{ textAlign: "center" }}>Edit Blog Post</h1>
      <div className={styles["align-right"]}>
        <span
          onClick={() => setConfirm(true)}
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </span>
      </div>
      <TextBox
        placeholder={"Title"}
        label="Title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        onBlur={() => {}}
      />
      <TextArea
        placeholder="Description"
        label="Description"
        onChange={(event) => setDescription(event.target.value)}
        value={description}
        type="text"
      />
      <TextEditor value={editorValue} quillObj={quillObj} />
      <button className={styles.btn} onClick={handleSubmit}>
        Update Blog
      </button>

      <button
        className={styles.cancel}
        onClick={() => navigate("./../../" + id)}
      >
        Cancel
      </button>

    </div>
  ) : (
    <h1>
      <Bars
        height="80"
        width="80"
        color="#474747"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClass="bars"
        visible={true}
      />
    </h1>
  );
}

export default EditBlog;
