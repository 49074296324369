import Header from "./components/header/Header";
import AppRoutes from "./Routing";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import styles from "./components/header/Header.module.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Footer from "./components/Footer"

function App() {
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    // console.log(loading)
    if (loading) return;
  }, [user, loading]);

  return (
    <div className="App">
      {loading && (
          <h1>
            <Bars
              height="80"
              width="80"
              color="#474747"
              ariaLabel="bars-loading"
              wrapperStyle={{justifyContent: "center"}}
              wrapperClass="bars"
              visible={true}
            />
          </h1>
      )}
      {!loading && (
        <Router>
          <Header user={user} loading={loading} />
          <ToastContainer
            position="top-right"
            autoClose={500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
              background: "#f6f3ed",
              color: "#465142",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "16px",
              padding: "30px 20px"
            }}
          />
          <div className={styles["main-div"]}>
            <AppRoutes user={user}/>
          </div>
          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
