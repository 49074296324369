import styles from "./Hardwood.module.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Vinyl() {
  document.title = "My Flooring Guy Now | Vinyl"

  const navigate = useNavigate();

  const faq = [
    {
      q: "Is SPC flooring really that great?",
      a:
        "Absolutely. SPC flooring combines the best of all worlds when it comes to durability, affordability, options, and beauty.",
    },
    {
      q: "Why haven't I heard of SPC flooring more often?",
      a:
        "SPC flooring is a new player in the flooring game, and is only readily available to the top suppliers in the nation. Our relationships with our suppliers have allowed us to offer this cutting-edge flooring to our customers way before the other guys!",
    },
    {
      q: "It's got to be expensive, right?",
      a:
        "Nope. The technology that goes into making SPC is affordable, and the materials involved are economical as well. This makes SPC an extremely cost effective option for customers.",
    },
  ];
  return (
    <div className={styles["products-container"]}>
      <div className={styles.hardwood}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className={styles["content-block"]}>
            <div className={styles.content}>
              <h2>About Vinyl</h2>

              <p>
                Vinyl plank flooring is composed of several layers, including a
                PVC vinyl backing, a printed design layer, and a clear wear
                layer that provides durability and resistance to scratches,
                stains, and moisture. The planks are typically designed to click
                together and float over the subfloor, making installation quick
                and easy.
              </p>

              <p>
                One of the key advantages of vinyl plank flooring is its
                affordability. Compared to natural hardwood flooring, vinyl
                plank flooring is often less expensive, making it a budget-
                friendly option for those who want the look of hardwood without
                the high cost. In addition, vinyl plank flooring is highly
                durable and low maintenance, making it ideal for high-traffic
                areas and busy households.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-37.jpg)",
              }}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["content-block"] + " " + styles.secondary}>
            <div className={styles.content}>
              <h2>Why Vinyl?</h2>
              Vinyl flooring is a popular choice for homeowners and businesses
              for several reasons.
              <p>
                Vinyl flooring is available in a wide variety of colors,
                patterns, and textures, allowing homeowners and businesses to
                customize their flooring to suit their decor style.
                Additionally, it is typically less expensive than natural
                hardwood flooring, making it a budget-friendly option for those
                who want the look of hardwood without the high cost.
              </p>
              <p>
                Overall, vinyl plank flooring is a practical, durable, and
                versatile flooring option that offers a range of benefits for
                homeowners and businesses. Its low maintenance requirements,
                affordability, ease of installation, and range of styles make it
                a popular choice for those who want a stylish and practical
                flooring solution.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-38.jpg)",
              }}
            />
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ y: 80 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ amount: 0.4, once: true }}
      >
        <center>
          <button
            className={styles["bottom-button"]}
            onClick={() => navigate("/get-estimate")}
          >
            Get Your Estimate
          </button>
        </center>
      </motion.div>

      <div className={styles.faq}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["faq-container"]}>
            <h1>Commonly Asked Questions</h1>
            {faq.map((item, index) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                key={index}
                sx={{
                  width: "100%",
                  backgroundColor: "#474747",
                  borderTop: "1px dashed #f6f3ed",
                  borderBottom: index + 1 == faq.length && "1px dashed #f6f3ed",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <FontAwesomeIcon
                      style={{ color: "#f6f3ed" }}
                      icon={faCaretDown}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: "#f6f3ed", fontSize: "18px" }}
                >
                  {item.q}
                </AccordionSummary>
                <AccordionDetails sx={{ color: "#f6f3ed" }}>
                  {item.a}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Vinyl;
