import { useState, useEffect } from "react";
import GalleryModal from "./GalleryModal";
import Photogrid from "./Photogrid";
import styles from "./GalleryPage.module.scss"

function GalleryPage() {

  document.title = "My Flooring Guy Now | Gallery"


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    function importAll(r) {
      return r.keys().map(r);
    }
    const images = importAll(require.context('./gallery-images', false, /\.(png|jpe?g|svg)$/));
    setPhotos((previous) => [...previous, ...images])
  }, []);

  const photosInitial = [
    "home-image.jpg",
    "home-main.jpg",
    "step1.jpg",
    "home-main.jpg",
    "home-image.jpg",
    "collections-bg.jpg",
    "home-image.jpg",
    "collections-bg.jpg",
  ];
  const [photos, setPhotos] = useState(photosInitial)
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);

  return (
    <div className={styles["gallery-container"]}>
      {/* <button onClick={() => setLightboxOpen(true)}>Open Modal</button> */}
      <h1>Gallery</h1>
      <GalleryModal
        setLightboxOpen={setLightboxOpen}
        index={imageIndex}
        setIndex={setImageIndex}
        lightboxOpen={lightboxOpen}
        photos={photos}
      />
      <Photogrid
        setIndex={setImageIndex}
        openModal={setLightboxOpen}
        photos={photos}
      />
    </div>
  );
}

export default GalleryPage;
