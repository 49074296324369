import Sidebar from "./Sidebar";
import styles from "./Dashboard.module.scss";
import { db } from "../../firebase";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import EntriesGrid from "./EntriesGrid";
import Dropdown from "../common/Dropdown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FilterMenu from "./FilterMenu";
import Checkbox from "../common/Checkbox";
import TextBox from "../common/TextBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Bars } from "react-loader-spinner";
import TablePagination from "@mui/material/TablePagination";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

function Dashboard(props) {
  const [entries, setEntries] = useState([]);

  const [loading, setLoading] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [data, setData] = useState({});
  const [isSelected, setIsSelected] = useState("Sort By");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    Active: false,
    Inactive: false,
    Repair: false,
    "Installation Only": false,
    "Installation/Material": false,
    Refinish: false,
    "Installation/Refinish": false,
  });
  //   const [type, setTypes] = useState({});

  const scrollRef = useRef(null);
  function scrollFunction(ref) {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    scrollFunction(scrollRef)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSettingData(id) {
    setDataId(id);
    setData(entries.filter((entry) => entry.id == id));
  }

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const submissions = await getDocs(collection(db, "submissions"));

      let entriesArray = [];
      submissions.forEach((doc) =>
        entriesArray.push({ id: doc.id, data: doc.data() })
      );
      setEntries(entriesArray);

      entriesArray.forEach((entry) => {
        const email = entry.data.email;
        const url = entry.data.data.imageURL;

        if (email && url) {
          const storage = getStorage();
          getDownloadURL(ref(storage, "/images/" + email + "/" + url)).then(
            (imageUrl) => {
              entry.image = imageUrl;
              setEntries((entries) => {
                return [...entries];
              });
            }
          );
        }
      });
    };
    getData();
    setLoading(false);
  }, []);

  function filterSearchEntries(filter, search) {
    let entriesCopy = [...entries];
    if (
      (filter.Active == false && filter.Inactive == false) ||
      (filter.Active == true && filter.Inactive == true)
    ) {
      entriesCopy = [...entries];
    } else if (filter.Active == true && filter.Inactive == false) {
      entriesCopy = entriesCopy.filter((entry) => entry.data.active == true);
    } else if (filter.Active == false && filter.Inactive == true) {
      entriesCopy = entriesCopy.filter((entry) => entry.data.active == false);
    }

    if (filter["Installation Only"] == true) {
      entriesCopy = entriesCopy.filter(
        (entry) => entry.data.data.type === "Installation Only"
      );
    } else if (filter["Repair"] == true) {
      entriesCopy = entriesCopy.filter(
        (entry) => entry.data.data.type === "Repair"
      );
    } else if (filter["Refinish"] == true) {
      entriesCopy = entriesCopy.filter(
        (entry) => entry.data.data.type === "Refinish"
      );
    } else if (filter["Installation/Material"] == true) {
      entriesCopy = entriesCopy.filter(
        (entry) => entry.data.data.type === "Installation/Material"
      );
    } else if (filter["Installation/Refinish"] == true) {
      entriesCopy = entriesCopy.filter(
        (entry) => entry.data.data.type === "Installation/Refinish"
      );
    }
    if (search != "") {
      entriesCopy = entriesCopy.filter((entry) =>
        searchFilter(entry, search.toLowerCase())
      );
    }

    return entriesCopy;
  }

  function searchFilter(entry, searchTerm) {
    return entry.data.name.toLowerCase().includes(searchTerm);
  }

  function handleSort(direction) {
    setIsSelected(direction);
    if (direction == "Name (asc)") {
      setEntries(entries.sort((a, b) => (a.data.name > b.data.name ? 1 : -1)));
    } else if (direction == "Name (desc)") {
      setEntries(entries.sort((a, b) => (a.data.name < b.data.name ? 1 : -1)));
    } else if (direction == "Date (asc)") {
      setEntries(
        entries.sort((a, b) => (Date(a.data.date) < Date(b.data.date) ? 1 : -1))
      );
    } else if (direction == "Date (desc)") {
      setEntries(
        entries.sort((a, b) => (Date(a.data.date) < Date(b.data.date) ? 1 : -1))
      );
    }
  }

  function handleFilter(event, input, checked) {
    setFilter((filters) => {
      return { ...filters, [input]: checked };
    });
  }

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Box>
    );
  }

  return (
    <div className={styles["dashboard-container"]} ref={scrollRef}>
      <center>
        <h1>Form Submissions</h1>
        <span>{entries.length} Submissions</span>
        {loading && (
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{
              display: "block !important)",
            }}
            wrapperClass="bars"
            visible={true}
          />
        )}
      </center>
      <FilterMenu>
      <div className={styles["filter-mobile"]}>
      <h2 style={{ marginBottom: 0 }}>Sort By</h2>
        <hr
          style={{
            backgroundColor: "black",
            height: "2px",
            width: "100%",
            border: 0,
          }}
        />
        <Dropdown selected={isSelected} handleSort={handleSort} />
        <h2 style={{ marginBottom: 0 }}>Filters</h2>
        <hr
          style={{
            backgroundColor: "black",
            height: "2px",
            width: "100%",
            border: 0,
          }}
        />
        <TextBox
          placeholder={"Search"}
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onBlur={() => {}}
        />
        <Accordion disableGutters elevation={0} square sx={{ width: "100%", backgroundColor:"#f6f3ed" }}>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Status
          </AccordionSummary>
          <AccordionDetails>
            <Checkbox
              handleFilter={handleFilter}
              label={"Active"}
              name={"Status"}
              checked={filter.Active}
            />
            <Checkbox
              handleFilter={handleFilter}
              label={"Inactive"}
              name={"Status"}
              checked={filter.Inactive}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters elevation={0} square sx={{ width: "100%", backgroundColor:"#f6f3ed" }}>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Work Type
          </AccordionSummary>
          <AccordionDetails>
            <Checkbox
              handleFilter={handleFilter}
              label={"Installation Only"}
              name={"Type"}
              checked={filter["Installation Only"]}
            />
            <Checkbox
              handleFilter={handleFilter}
              label={"Installation/Material"}
              name={"Type"}
              checked={filter["Installation/Material"]}
            />
            <Checkbox
              handleFilter={handleFilter}
              label={"Repair"}
              name={"Type"}
              checked={filter.Repair}
            />
            <Checkbox
              handleFilter={handleFilter}
              label={"Refinish"}
              name={"Type"}
              checked={filter.Refinish}
            />
            <Checkbox
              handleFilter={handleFilter}
              label={"Installation/Refinish"}
              name={"Type"}
              checked={filter["Installation/Refinish"]}
            />
          </AccordionDetails>
        </Accordion>
        </div>
      </FilterMenu>
      <div className={styles.dashboard}>
        {!loading && (
          <div className={styles["filter-desktop"]}>
            <Dropdown selected={isSelected} handleSort={handleSort} />
            <h2 style={{ marginBottom: 0 }}>Filters</h2>
            <hr
              style={{
                backgroundColor: "black",
                height: "2px",
                width: "100%",
                border: 0,
              }}
            />
            <TextBox
              placeholder={"Search"}
              label="Search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              onBlur={() => {}}
            />
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{ width: "100%", backgroundColor:"#f6f3ed" }}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Status
              </AccordionSummary>
              <AccordionDetails>
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Active"}
                  name={"Status"}
                  checked={filter.Active}
                />
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Inactive"}
                  name={"Status"}
                  checked={filter.Inactive}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{ width: "100%", backgroundColor:"#f6f3ed" }}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Work Type
              </AccordionSummary>
              <AccordionDetails>
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Installation Only"}
                  name={"Type"}
                  checked={filter["Installation Only"]}
                />
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Installation/Material"}
                  name={"Type"}
                  checked={filter["Installation/Material"]}
                />
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Repair"}
                  name={"Type"}
                  checked={filter.Repair}
                />
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Refinish"}
                  name={"Type"}
                  checked={filter.Refinish}
                />
                <Checkbox
                  handleFilter={handleFilter}
                  label={"Installation/Refinish"}
                  name={"Type"}
                  checked={filter["Installation/Refinish"]}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        {!loading && (
          <EntriesGrid
            page={page}
            rowsPerPage={rowsPerPage}
            entries={filterSearchEntries(filter, search)}
          />
        )}
      </div>
      <center>
        <TablePagination
          rowsPerPageOptions={[
            3,
            10,
            25,
            { label: "All", value: filterSearchEntries(filter, search).length },
          ]}
          colSpan={3}
          count={filterSearchEntries(filter, search).length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </center>
    </div>
  );
}

export default Dashboard;
