import styles from "./EntriesGrid.module.scss";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "zipcode",
    numeric: true,
    disablePadding: false,
    label: "Zipcode",
  },
  {

  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.label == "Type" || headCell.label == "Zipcode" ? styles.mobile : ""}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EntriesGrid(props) {
  const navigate = useNavigate()
  const [currentEntries, setCurrentEntries] = useState(props.entries);

  useEffect(() => {
    setCurrentEntries(props.entries);
  }, [JSON.stringify(props.entries)]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function formatDate(date){
    // console.log(new Date(date).getMinutes())
    // console.log(new Date(date).toLocaleString())

    return new Date(date).toDateString()
  }

  return (
    <TableContainer className={styles.table}>
      <Table aria-labelledby="tableTitle">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={currentEntries.length}
        />
        <TableBody>
          {stableSort(currentEntries, getComparator(order, orderBy)).slice(
        props.newPage * props.rowsPerPage,
        props.newPage * props.rowsPerPage + props.rowsPerPage,
      ).map(
            (row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>
                    <p>{row.name}</p>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell className={styles.mobile}>{row.type}</TableCell>
                  <TableCell>{formatDate(row.date)}</TableCell>
                  <TableCell align="right" className={styles.mobile}>{row.zipcode}</TableCell>
                  <TableCell align="right"><button onClick={() => navigate("/submissions/" + row.id)}>View</button></TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EntriesGrid;
