import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = (props) => {

  // console.log(props.user)
  // console.log(process.env.REACT_APP_ADMIN)
  let location = useLocation();

  if (!props.user || (props.user.email !== process.env.REACT_APP_ADMIN1 && props.user.email !== process.env.REACT_APP_ADMIN2)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return props.children;
};

export default ProtectedRoute;
