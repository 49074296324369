export const installationData = {
  tabName: "Installation",
  name: "Installation",
  title: "Installation Made Easy",
  title2: "What We Install",
  image1: process.env.PUBLIC_URL + "/images/pexels-13.jpg",
  image2: process.env.PUBLIC_URL + "/images/pexels-14.jpg",
  slogan:
    "We make installation hassle-free, so you can enjoy your floor quicker… and without waiting for a headache to go away first. We install all wood and wood-like products such as Solid Hardwood, Engineered Hardwood, Vinyl and Laminate.",
  section1:
    "At My Flooring Guy, we understand that flooring installation can be a challenging task for many homeowners, which is why we have assembled a team of highly skilled and experienced flooring installers who have the knowledge and expertise to make the process as seamless as possible. With our deep knowledge of the latest flooring products and technologies, we can install your new floor quickly, effectively, and efficiently, with minimal disruption to your daily routine. Whether you require a simple installation, replacement, or something more complex, you can trust our team to deliver a high-quality service that meets your specific needs and exceeds your expectations.",
  section2:
    "Whether its a simple install over your existing floor, a replacement, or something else, you can rest easy knowing our team will get the job done easily.",
  section3:
    "At My Flooring Guy, we offer a wide range of installation services to fit your flooring needs. Our expert installers are experienced in installing various types of flooring including Vinyl, Laminate, and Hardwood. Whether you're looking to upgrade your home's style or functionality, we've got you covered. Our team is dedicated to delivering high-quality results with a smooth and hassle-free installation process, so you can enjoy your new floors in no time.",
  section4:
    "",
  faq: [
    {
      q: "Do you install floors from other suppliers?",
      a:
        "Yes! While we encourage our customers to take a look at our flooring options, " +
        "we’ll happily install flooring that you already have as well.",
    },
    {
      q: "How do you price for hardwood installation?",
      a:
        "Pricing is based on a number of factors, including but not limited to square footage, " +
        "type of material, and the number of installers required.",
    },
    {
      q: "Can I buy flooring from you and have it installed by someone else?",
      a:
        "We install all flooring purchased from us, to ensure the installation is up to snuff and leaves the customer satisfied." +
        "There’s nothing worse than a beautiful piece of flooring damaged by a third party that isn’t careful!",
    },
  ],
};

export const refinishData = {
  tabName: "Refinish",
  name: "Refinishing",
  title: "Refinishing Done Right",
  title2: "Our Process",
  image1: process.env.PUBLIC_URL + "/images/pexels-15.jpg",
  image2: process.env.PUBLIC_URL + "/images/pexels-16.jpg",
  slogan:
    "Life’s too short for old, scratched, scuffed floors. Let our experts bring your floors back to life!",
  section1:
    "If you're looking for a way to revitalize your aging hardwood floors, we're here to assist you. Our hardwood refinishing services are designed to restore your floors to their original beauty and shine, no matter the type of hardwood. Our expert team can help you achieve your desired look, whether it involves changing the color of your floors, bringing out their original luster and shine, or smoothing out rough surfaces. Refinishing is a cost-effective way to give your floors a fresh, new appearance and add value to your home, making it an investment you won't regret.",
  section2:
    "No matter the type of hardwood you have, we can refinish it. Choose refinishing if you’re looking to change the color of your floors, bring out the original luster and shine, or smooth out rough surfaces. Refinishing is a great, cost-effective way to roll back the hands of time and make your floor look young again!",
  section3:
    "During our refinishing process, our skilled team of refinishers will thoroughly sand down the top layer of your existing floor. We use three sanding sessions with progressively lighter grit paper each time. We start with a coarse 30-40 grit sandpaper to remove deeper scratches, then transition to a 50-60 grit to create a smooth and even surface, and finally, use a fine 80-100 grit to soften and polish the floors.",
  section4:
    "After sanding, we carefully vacuum the floors with a soft attachment to give them a high-quality finish. We then apply a stain to the floors, which we select in consultation with you, to achieve the desired look. Our refinishing process will transform your worn-out floors into a stunning, like-new surface.",
  faq: [
    {
      q: "How long will refinishing take?",
      a:
        "Every floor is different, but your project manager will be able to give you an estimate of the time and manpower it will take in your initial, in-home consultation!",
    },
    {
      q: "Can I just get refinishing service without buying flooring?",
      a:
        "Absolutely. We’ll refinish existing hardwood floors no matter where you bought them from!",
    },
    {
      q: "What will my floors like like after refinishing?",
      a:
        "After your floor has been refinished by our experts, it will look like new. We’ll remove scratches, scrapes, and blemishes to breathe years of life back into your floor in no time!",
    },
  ],
};

export const repairData = {
  tabName: "Repair",
  name: "Repairing",
  title: "Your Repair, Simplified",
  title2: "How We Repair",
  image1: process.env.PUBLIC_URL + "/images/pexels-17.jpg",
  image2: process.env.PUBLIC_URL + "/images/pexels-18.jpg",
  slogan:
    "Don’t let cracks and scratches ruin your floor. Let our experts fix it today!",
  section1:
    "Need your floor repaired? Our experts are on the job. We repair hardwood, laminate, vinyl, and SPC flooring– so if you walk on it, chances are we can fix it.",
  section2:
    "Some of the most common repair services we provide include buffing scratches, repairing and replacing water damaged areas, filling cracks, replacing loose boards and plans, and replacing mold damaged areas. The best part? Our services are fast, affordable, and reliable!",
  section3:
    "If you call for same day service and describe the problem in detail over the phone, our expert team will simply come out to your location, fix the floor, and be on their way! We make it as simple as possible.",
  section4:
    "For larger repairs that require more work or manpower, we’ll start with an in-home consultation. The project manager assigned to your repair will determine the amount of work that’s required, whether or not additional products or tools will be needed, and the amount of time the repair will take. After all of these aspects have been determined and a price has been agreed to, we’ll get right to work! Our goal with every repair project is to get it done quickly and efficiently– and to exceed the customer’s expectations!",
  faq: [
    {
      q: "Can I really get same day service?",
      a:
        "Yes! Our team is always here for folks that need quick repairs done. Just call in and describe your problem in detail. If it’s a job that can be accomplished quickly and within business hours, we’ll be right over!",
    },
    {
      q: "Do you only repair hardwood?",
      a:
        "Nope. We also repair SPC flooring, laminate, and vinyl. If it’s in your home or office and you walk on it, chances are we can repair it!",
    },
    {
      q: "How do you charge for repairs?",
      a:
        "Repairs are charged based on hours, products needed, manpower and more. We’ll always ensure you’re comfortable with the price before we start any repair work!",
    },
  ],
};
