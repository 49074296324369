import styles from "./HowItWorks.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./HowItWorksData";
import HowItWorksTemplate from "./HowItWorksTemplate";
import { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

function HowItWorksPage() {
  document.title = "My Flooring Guy Now | How It Works"

  const refStep1 = useRef(null);
  const refStep2 = useRef(null);
  const refStep3 = useRef(null);

  const [visible, setVisible] = useState(false);

  const refs = [refStep1, refStep2, refStep3];
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop - 40,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = window.pageYOffset;
      var elementTarget = document.getElementsByClassName(
        "scroll-div-target"
      )[0];

      if (scrolled > elementTarget.offsetTop + elementTarget.offsetHeight) {
        setVisible(true);
      } else if (
        scrolled <=
        elementTarget.offsetTop + elementTarget.offsetHeight
      ) {
        setVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className={styles["hiw-section"]}>
      <div>
        <div className={styles["hiw-main"]}>
          <div
            className={styles["hiw-top"]}
          >
            <h2>How It Works</h2>
            <button>Schedule Now</button>
          </div>
          <div className={styles["hiw-bottom"] + " " + "scroll-div-target"}>
            <div
              onClick={() => {
                handleScroll(refStep1.current);
              }}
            >
              <span className={styles.number}>1</span>
              <span className={styles.text}>Plan</span>
            </div>
            <div
              onClick={() => {
                handleScroll(refStep2.current);
              }}
            >
              <span className={styles.number}>2</span>
              <span className={styles.text}>Refine</span>
            </div>
            <div
              onClick={() => {
                handleScroll(refStep3.current);
              }}
            >
              <span className={styles.number}>3</span>
              <span className={styles.text}>Build</span>
            </div>
          </div>
        </div>
      </div>

      {data.map((item, index) => (
        <HowItWorksTemplate
          data={item}
          index={index}
          key={index}
          myRef={refs[index]}
        />
      ))}
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -80, opacity: 0 }}
            transition={{ duration: 1 }}
            className={styles["hiw-bottom-nav-container"]}
          >
            <div className={styles["hiw-bottom-nav"]}>
              <div
                onClick={() => {
                  handleScroll(refStep1.current);
                }}
              >
                <span className={styles.number}>1</span>
                <span className={styles.text}>Plan</span>
              </div>
              <div
                onClick={() => {
                  handleScroll(refStep2.current);
                }}
              >
                <span className={styles.number}>2</span>
                <span className={styles.text}>Design</span>
              </div>
              <div
                onClick={() => {
                  handleScroll(refStep3.current);
                }}
              >
                <span className={styles.number}>3</span>
                <span className={styles.text}>Build</span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}

export default HowItWorksPage;
