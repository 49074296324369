export const refinishQuestions = [
    {
        q: "Are you looking to add a new stain color for your refinish?",
        stateToSet: "New-Stain",
        list: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
        {
          value: "Not sure",
          label: "Not sure, lets discuss!",
        },
      ]},
  
    {
      q: "How many square feet are you looking to install?",
      stateToSet: "Square-Feet",
      list: [
      {
        value: "100-250",
        label: "100-250",
      },
      {
        value: "250-500",
        label: "250-500",
      },
      {
        value: "500-750",
        label: "500-750",
      },
      {
        value: "750-1200",
        label: "750-1200",
      },
      {
        value: "1200+",
        label: "1200+",
      },
    ]},
  
    {
      q: "Will there be stairs involved in the project?",
      stateToSet: "Stairs",
      list: [
      {
        value: "Yes",
        label: "Yes",
      },
      {
        value: "No",
        label: "No",
      },
      {
        value: "Not sure",
        label: "Not sure, lets discuss!",
      },
    ]},

    // {
    //     q: "Are you looking for material installation as well?",
    //     stateToSet: "Material-Installation",
    //     list: [
    //     {
    //       value: "Yes",
    //       label: "Yes",
    //     },
    //     {
    //       value: "No",
    //       label: "No",
    //     },
    //     {
    //       value: "Not sure",
    //       label: "Not sure, lets discuss!",
    //     },
    //   ]},
  ];
  