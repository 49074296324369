import { useState } from "react";
import ModalBase from "./../common/Modal";
import styles from "./../gallery/GalleryModal.module.scss";

function Popup(props) {
  return (
    <div className={styles["modal-container"]}>
      <ModalBase
        isOpen={props.lightboxOpen}
        close={() => props.setLightboxOpen(false)}
      >
        <div className={styles["modal-content"]} tabIndex="0">
          <div className={styles.main}>
            <img src={props.src} />
          </div>
        </div>
      </ModalBase>
    </div>
  );
}

export default Popup;
