import styles from "./Hardwood.module.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function SPC() {
  const navigate = useNavigate();

  document.title = "My Flooring Guy Now | SPC"

  const faq = [
    {
      q: "Is SPC flooring really that great?",
      a:
        "Absolutely. SPC flooring combines the best of all worlds when it comes to durability, affordability, options, and beauty.",
    },
    {
      q: "Why haven't I heard of SPC flooring more often?",
      a:
        "SPC flooring is a new player in the flooring game, and is only readily available to the top suppliers in the nation. Our relationships with our suppliers have allowed us to offer this cutting-edge flooring to our customers way before the other guys!",
    },
    {
      q: "It's got to be expensive, right?",
      a:
        "Nope. The technology that goes into making SPC is affordable, and the materials involved are economical as well. This makes SPC an extremely cost effective option for customers.",
    },
  ];
  return (
    <div className={styles["products-container"]}>
      <div className={styles.hardwood}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className={styles["content-block"]}>
            <div className={styles.content}>
              <h2>About SPC</h2>

              <p>
                SPC flooring, short for Stone Plastic Composite flooring, is a
                popular type of resilient flooring. SPC flooring offers several
                advantages that make it a popular choice for homeowners and
                businesses. One of the main advantages of SPC flooring is its
                durability and resistance to wear and tear. With its strong core
                and protective top layer, SPC flooring can withstand heavy foot
                traffic, spills, and scratches, making it ideal for high-traffic
                areas and busy households.
              </p>

              <p>
                In addition to its durability, SPC flooring is also waterproof,
                making it a great choice for spaces prone to spill,, such as
                bathrooms, kitchens, and basements. It is also highly versatile
                and comes in a wide range of colors, patterns, and textures to
                suit any style and taste.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-39.jpg)",
              }}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["content-block"] + " " + styles.secondary}>
            <div className={styles.content}>
              <h2>Why SPC?</h2>
              <p>
                SPC flooring also comes in a wide range of styles and colors,
                and can mimic the look of natural materials such as hardwood and
                stone, without the high cost and maintenance. In addition SPC
                flooring is made up of several layers, including a rigid core
                that is composed of limestone and PVC, and a wear layer that is
                highly resistant to scratches and stains.
              </p>
              <p>
                Overall, SPC flooring is a versatile and durable flooring option
                that offers a range of benefits for homeowners and businesses
                alike. Whether you are looking for a cost- effective flooring
                solution, or a durable and water proof flooring option for your
                home or office, SPC flooring is a great choice.
              </p>
            </div>

            <div
              className={styles["image"]}
              style={{
                backgroundImage:
                  "url(" + process.env.PUBLIC_URL + "/images/pexels-41.jpg)",
              }}
            />
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ y: 80 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ amount: 0.4, once: true }}
      >
        <center>
          <button
            className={styles["bottom-button"]}
            onClick={() => navigate("/get-estimate")}
          >
            Get Your Estimate
          </button>
        </center>
      </motion.div>

      <div className={styles.faq}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ amount: 0.4, once: true }}
        >
          <div className={styles["faq-container"]}>
            <h1>Commonly Asked Questions</h1>
            {faq.map((item, index) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                key={index}
                sx={{
                  width: "100%",
                  backgroundColor: "#474747",
                  borderTop: "1px dashed #f6f3ed",
                  borderBottom: index + 1 == faq.length && "1px dashed #f6f3ed",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <FontAwesomeIcon
                      style={{ color: "#f6f3ed" }}
                      icon={faCaretDown}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: "#f6f3ed", fontSize: "18px" }}
                >
                  {item.q}
                </AccordionSummary>
                <AccordionDetails sx={{ color: "#f6f3ed" }}>
                  {item.a}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default SPC;
