import styles from "./BlogPage.module.scss";
import { Bars } from "react-loader-spinner";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "./../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faPencil } from "@fortawesome/free-solid-svg-icons";

function BlogPage(props) {
  const { id } = useParams();
  const [data, setData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const submission = (await getDoc(doc(db, "blogs", id))).data();
      setData(submission);
    };
    getData();
  }, [id]);

  return data ? (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.flex}>
          <span className={styles.breadcrumb} onClick={() => navigate("./../")}>
            <FontAwesomeIcon icon={faArrowLeftLong} /> Back
          </span>

          {props.user && <span
            className={styles.breadcrumb}
            onClick={() => navigate("/blog/edit/" + id)}
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </span>}
        </div>

        <h2>{data.title}</h2>

        <p>{new Date(data.date).toDateString()}</p>
      </div>

      <div
        className={styles["blog-container"]}
        dangerouslySetInnerHTML={{ __html: data.data }}
      ></div>
    </div>
  ) : (
    <h1>
      <Bars
        height="80"
        width="80"
        color="#474747"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClass="bars"
        visible={true}
      />
    </h1>
  );
}

export default BlogPage;
