import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import styles from "./HowItWorksTemplate.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function HowItWorksTemplate(props) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div ref={props.myRef} className={styles["hiw-template-main"]}>
      <h1>{props.data.title}</h1>
      <div
        className={
          styles["hiw-template-flex"] +
          (props.index % 2 != 0 ? " " + styles["odd"] : "")
        }
      >
        <div className={styles["hiw-flex-left"]}>
          <img src={process.env.PUBLIC_URL + "/images/" + props.data.image} />
        </div>

        <div className={styles["hiw-flex-right"]}>
          <p>{props.data.summary}</p>
        </div>
      </div>
    </div>
  );
}

export default HowItWorksTemplate;
