import { motion } from "framer-motion";
import styles from "./Installation.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function ServicesTemplate(props) {

  document.title = "My Flooring Guy Now | " + props.data.tabName

  const navigate = useNavigate();
  return (
    <>
      <div className={styles.hardwood}>
        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h1>{props.data.title}</h1>
          <p>{props.data.slogan}</p>

          <div className={styles["content-block"]}>
            <div className={styles.content}>
              <h2>About {props.data.name}</h2>
              <p>{props.data.section1}</p>

              {/* <p>{props.data.section2}</p> */}
            </div>

            <div
              className={styles["image"]}
              style={{ backgroundImage: "url(" + props.data.image1 + ")" }}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className={styles["content-block"] + " " + styles.secondary}>
            <div className={styles.content}>
              <h2 style={{ marginTop: "0.67em" }}>{props.data.title2}</h2>
              <p>{props.data.section3}</p>

              <p>{props.data.section4 != "" && props.data.section4}</p>
            </div>

            <div
              className={styles["image"]}
              style={{ backgroundImage: "url(" + props.data.image2 + ")" }}
            />
          </div>
        </motion.div>
      </div>

      <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        ><center><button className={styles["bottom-button"]} onClick={() => navigate("/get-estimate")}>
        Get Your Estimate
      </button></center></motion.div>

      <div className={styles.faq}>
      <motion.div
          initial={{ y: 80 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
        <div className={styles["faq-container"]}>
          <h1>Commonly Asked Questions</h1>
          {props.data.faq.map((item, index) => (
            <Accordion
              disableGutters
              elevation={0}
              square
              key={index}
              sx={{
                width: "100%",
                backgroundColor: "#465142;",
                borderTop: "1px dashed #f6f3ed",
                borderBottom:
                  index + 1 == props.data.faq.length && "1px dashed #f6f3ed",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <FontAwesomeIcon
                    style={{ color: "#f6f3ed" }}
                    icon={faCaretDown}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ color: "#f6f3ed", fontSize: "18px" }}
              >
                {item.q}
              </AccordionSummary>
              <AccordionDetails sx={{ color: "#f6f3ed" }}>
                {item.a}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        </motion.div>
      </div>

    </>
  );
}

export default ServicesTemplate;
