import { useState, useEffect } from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import styles from "./../../styles/Steps.module.scss";
import ProgressBar from "./../common/ProgressBar";
import SubmissionPage from "./SubmissionPage";

function EstimateForm(props) {
  document.title = "My Flooring Guy Now | Get Estimate";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState("forward");
  const [genericValues, setGenericValues] = useState({
    zipcode: "",
    type: "",
  });

  const [values, setValues] = useState({});

  useEffect(() => {
    if (values["Square-Feet"] != "Not Sure" && values["Square-Feet-Rooms"]) {
      let valuesCopy = { ...values };
      delete valuesCopy["Square-Feet-Rooms"];

      setValues(valuesCopy);
    }
  }, [values["Square-Feet"]]);

  const [images, setImages] = useState([]);
  const [spaceImages, setSpaceImages] = useState([]);


  function nextStep() {
    setStep((step) => (step += 1));
    setDirection("forward");
  }

  function prevStep() {
    setStep((step) => (step -= 1));
    setDirection("back");
  }

  function handleChange(event, input) {
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  function handleGenericChange(event, input) {
    if (input === "type") {
      setValues({});
      setImages([]);
    }
    setGenericValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  return (
    <div>
      <div className={styles["steps-main-container"]}>
        {step < 5 && (
          <ProgressBar bgcolor={"#474747"} completed={(step / 4) * 100} />
        )}
        {step < 5 && (
          <h1 style={{ textTransform: "uppercase"}}>Build Your Estimate</h1>
        )}

        <div className={styles["steps-second-level-cont"]}>
          {step === 1 && (
            <FirstStep
              handleChange={handleGenericChange}
              next={nextStep}
              values={genericValues}
              direction={direction}
            />
          )}

          {step === 2 && (
            <SecondStep
              handleChange={handleChange}
              back={prevStep}
              next={nextStep}
              values={values}
              type={genericValues.type}
              direction={direction}
              setValues={setValues}
            />
          )}

          {step === 3 && (
            <ThirdStep
              handleChange={handleChange}
              back={prevStep}
              next={nextStep}
              values={values}
              direction={direction}
              user={props.user}
              setValues={setValues}
              setImages={setImages}
              images={images}
              setSpaceImages={setSpaceImages}
              spaceImages={spaceImages}
            />
          )}

          {step === 4 && (
            <FourthStep
              handleChange={handleChange}
              back={prevStep}
              next={nextStep}
              values={values}
              genericValues={genericValues}
              direction={direction}
              images={images}
              spaceImages={spaceImages}
            />
          )}
        </div>
        <div>
          {step === 5 && (
            <SubmissionPage values={values} direction={direction} />
          )}
        </div>
      </div>
    </div>
  );
}

export default EstimateForm;
