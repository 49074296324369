import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import styles from "./Filter.module.scss";

export default function FilterMenu(props) {
  const [anchor, setAnchor] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchor(open);
  };

  return (
    <div key={"left"} className={styles["mobile-filter"]}>
      <button onClick={toggleDrawer(true)}>Open</button>
      <Drawer
        PaperProps={{
          sx: {
            width: 350,
          },
        }}
        
        anchor={"left"}
        open={anchor}
        onClose={toggleDrawer(false)}
      >
        {props.children}
      </Drawer>
    </div>
  );
}
