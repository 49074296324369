import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./components/admin/Dashboard";
import EstimateForm from "./components/estimate-form/EstimateForm";
import Home from "./components/home/Home";
import Login from "./components/log-in/Login";
import { useEffect, useState } from "react";
import { db } from "./firebase";
import LandingPage1 from "./components/landing-pages/LandingPage1";
import LandingPage1Form from "./components/landing-pages/LandingPage1Form";
import LandingPageMain from "./components/landing-pages/LandingPageMain";
import GalleryPage from "./components/gallery/GalleryPage";
import HowItWorksPage from "./components/how-it-works/HowItWorksPage";
import ProtectedRoute from "./components/admin/ProtectedRoute";
import InvalidPage from "./components/common/InvalidPage";
import SubmissionsMain from "./components/admin/SubmissionsMain";
import Submission from "./components/admin/Submission";
import Services from "./components/services/Services";
import Products from "./components/products-new/Products";
import Blogs from "./components/blog/Blogs";
import CreateBlog from "./components/blog/CreateBlog";
import BlogPage from "./components/blog/BlogPage";
import EditBlog from "./components/blog/EditBlog";

function AppRoutes(props) {
  // let location = useLocation();
  // const [landingPage1, setLandingPage1] = useState([]);

  // const [loading, setLoading] = useState(false);

  return (
    <Routes>
      <Route path="get-estimate" element={<EstimateForm />} />
      <Route path="log-in" element={<Login />} />
      {/* <Route path="register" element={<Register />} /> */}

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute user={props.user}>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="submissions/:id"
        element={
          <ProtectedRoute user={props.user}>
            <Submission />
          </ProtectedRoute>
        }
      />

      <Route
        path="submissions"
        element={
          <ProtectedRoute user={props.user}>
            <SubmissionsMain />
          </ProtectedRoute>
        }
      />
      <Route path="how-it-works" element={<HowItWorksPage />} />

      <Route path="products" element={<Products />} />

      <Route path="hardwood" element={<Products product="Hardwood" />} />

      <Route
        path="laminateflooring"
        element={<Products product="Laminate" />}
      />

      <Route
        path="vinyl-tile-flooring"
        element={<Products product="Vinyl" />}
      />

      <Route path="spc-tile-flooring" element={<Products product="SPC" />} />

      <Route
        path="floor-installation"
        element={<Services service="Installation" />}
      />
      <Route
        path="hardwood-floor-refinish"
        element={<Services service="Refinish" />}
      />
      <Route
        path="hardwood-floor-repair"
        element={<Services service="Repair" />}
      />

      <Route path="services" element={<Services />} />
      <Route path="gallery" element={<GalleryPage />} />

      <Route path="landing-pages" element={<LandingPageMain />} />

      <Route path="landing-page-1" element={<LandingPage1Form />} />

      <Route path="/blog" element={<Blogs />} />

      <Route
        path="/create-blog"
        element={
          <ProtectedRoute user={props.user}>
            <CreateBlog />
          </ProtectedRoute>
        }
      />

      <Route path="/blog/:id" element={<BlogPage user={props.user} />} />

      <Route
        path="/blog/edit/:id"
        element={
          <ProtectedRoute user={props.user}>
            <EditBlog />
          </ProtectedRoute>
        }
      />

      {/* {landingPage1.length > 0 &&
        !loading &&
        landingPage1.map((item, index) => (
          <Route
            key={index}
            path={item.data.url}
            element={<LandingPage1 data={item.data} />}
          />
        ))} */}

      <Route path="/" element={<Home />} />

      <Route path="*" element={<InvalidPage />} />
    </Routes>
  );
}

export default AppRoutes;
