import styles from "./InvalidPage.module.scss"

function InvalidPage() {
  return (
    <div className={styles.invalid}>
      <h1>404 Error</h1>
      <p>
        You have reached an unsupported URL, please use the site navigation to
        return to a supported page.
      </p>
    </div>
  );
}

export default InvalidPage;
