import styles from "./ActiveLandingPages.module.scss";
import { doc, collection, getDocs, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "./../../firebase";
import { useNavigate } from "react-router-dom";

function ActiveLandingPages(props) {
  const navigate = useNavigate();

  const [landingPages, setLandingPages] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const entries = await getDocs(collection(db, "landing-pages"));

      let entriesArray = [];
      entries.forEach((doc) =>
        entriesArray.push({ id: doc.id, data: doc.data() })
      );
      setLandingPages(entriesArray);
    };
    getData();
    setLoading(false);
  }, []);

  async function deletePage(id) {
    await deleteDoc(doc(db, "landing-pages", id)).then(() =>
      setLandingPages([
        ...landingPages.filter((landingPage) => landingPage.id !== id),
      ])
    );
  }

  return (
    <div className={styles["landing-page-main"]}>
      <div className={styles["landing-pages-flex"]}>
        {!loading &&
          landingPages.length > 0 &&
          landingPages.map((item, index) => (
            <div className={styles.item} key={index}>
              <h2>{item.data.title}</h2>
              <p>{item.data.url}</p>
              <p>{item.data.type}</p>
              <br />
              <div className={styles["buttons-div"]}>
                <button onClick={() => navigate("../" + item.data.url)}>
                  View
                </button>
                <button onClick={() => deletePage(item.id)}>Delete</button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ActiveLandingPages;
