import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import styles from "./header/Header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import { logout } from "./../firebase";

import "./../styles/muioverrides.scss";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f6f3ed;
  // transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  //   height: 80vh;
  width: 100%;
  text-align: left;
  padding: 60px 20px 20px;
  position: absolute;
  padding-top: 80px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  z-index: 3;

  hr {
    background-color: #474747;
    height: 2px;
    border: none;
    width: 100%;
    // padding: 0 22px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const BlackBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  transition: transform 0.3s ease-in-out;
`;

const Menu = ({ myRef, open, loading, user, setOpen }) => {
  let activeStyle = {
    textDecoration: "underline",
  };

  return (
    <BlackBackground open={open}>
      <StyledMenu ref={myRef}>
        <div className={styles["mobile-item"]}>
          <NavLink to="how-it-works" onClick={() => setOpen(false)}>
            <span>How It Works</span>
          </NavLink>
        </div>

        <hr />

        <div className={styles["mobile-item"]}>
          <NavLink to="products" onClick={() => setOpen(false)}>
            <span>Products</span>
          </NavLink>
        </div>

        <hr />

        <div className={styles["mobile-item"]}>
          <NavLink to="services" onClick={() => setOpen(false)}>
            <span>Services</span>
          </NavLink>
        </div>

        <hr />

        <div className={styles["mobile-item"]}>
          <NavLink to="gallery" onClick={() => setOpen(false)}>
            <span>Gallery</span>
          </NavLink>
        </div>

        <hr />

        {user && (user.email === process.env.REACT_APP_ADMIN1 || user.email === process.env.REACT_APP_ADMIN2) && (
          <>
            <div className={styles["mobile-item"]}>
              <NavLink to="submissions" onClick={() => setOpen(false)}>
                <span>Submissions</span>
              </NavLink>
            </div>

            <hr />

            <div className={styles["mobile-item"]}>
              <NavLink to="create-blog" onClick={() => setOpen(false)}>
                <span>Create Blog</span>
              </NavLink>
            </div>

            <hr />

            <div className={styles["mobile-item"]}>
              <NavLink
                onClick={() => {
                  logout();
                  setOpen(false);
                }}
              >
                Log Out
              </NavLink>
            </div>

            <hr />
          </>
        )}

        {/* {loading && <></>}
        {!loading && user === undefined && (
          <div className={styles["mobile-item"]}>
            <NavLink
              to="log-in"
              onClick={() => setOpen(false)}
            >
              <span>Log in</span>
            </NavLink>
          </div>
        )}

        <hr /> */}
        <div className={styles["contacts"]}>
          <a href="tel:+17147948099" className={styles.icons}>
            <FontAwesomeIcon icon={faPhone} />
          </a>
          <a
            href="mailto:office@myflooringguynow.com"
            className={styles.icons}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            href="https://www.instagram.com/myflooringguy/"
            target="_blank"
            className={styles.icons}
          >
            <FontAwesomeIcon
              style={{ fontSize: "20px", width: "20px" }}
              icon={faInstagram}
            />
          </a>
        </div>
      </StyledMenu>
    </BlackBackground>
  );
};

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  position: relative;
  float: left;
  margin: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    // background: ${({ open }) => (open ? "#474747" : "#465142")};
    background: #465142;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger id="burger-button" open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

function BurgerMenu(props) {
  const buttonId = document.getElementById("burger-button");
  const [open, setOpen] = React.useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false), buttonId);
  return (
    <div>
      <Burger open={open} setOpen={setOpen} />
      <Menu
        user={props.user}
        loading={props.loading}
        myRef={node}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

const useOnClickOutside = (ref, handler, id) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        ref.current == event.target ||
        ref.current.contains(event.target) ||
        document.getElementById("burger-button") == event.target ||
        document.getElementById("burger-button") == event.target.parentElement
      ) {
        return;
      } else {
        handler(event);
      }
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default BurgerMenu;
