import styles from "./Dashboard.module.scss";
import { db } from "../../firebase";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import EntriesGrid from "./EntriesGrid";
import TextBox from "../common/TextBox";
import { Bars } from "react-loader-spinner";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

function SubmissionsMain(props) {
  document.title = "My Flooring Guy Now | Submissions";

  const [entries, setEntries] = useState([]);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // scrollFunction(scrollRef)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const submissions = await getDocs(collection(db, "submissions"));

      let entriesArray = [];
      submissions.forEach((doc) =>
        entriesArray.push({ id: doc.id, data: doc.data() })
      );
      setEntries(entriesArray);

      entriesArray.forEach((entry) => {
        const email = entry.data.email;
        const url = entry.data.data.imageURL;

        if (email && url) {
          const storage = getStorage();
          getDownloadURL(ref(storage, "/images/" + email + "/" + url)).then(
            (imageUrl) => {
              entry.image = imageUrl;
              setEntries((entries) => {
                return [...entries];
              });
            }
          );
        }
      });
    };
    getData();
    setLoading(false);
  }, []);

  function filterSearchEntries(search) {
    let entriesCopy = [...entries];
    // console.log(entriesCopy);

    if (search != "") {
      entriesCopy = entriesCopy.filter((entry) =>
        searchFilter(entry, search.toLowerCase())
      );
    }

    entriesCopy = entriesCopy.map((entry) => {
      return {
        name: entry.data.name,
        email: entry.data.email,
        date: entry.data.date,
        zipcode: entry.data.zipcode,
        type: entry.data.type,
        id: entry.id,
      };
    });
    return entriesCopy;
  }

  function searchFilter(entry, searchTerm) {
    return entry.data.name.toLowerCase().includes(searchTerm);
  }

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Box>
    );
  }

  return (
    <div className={styles["dashboard-container"]}>
      <center>
        <h1>Form Submissions</h1>
        <span>{entries.length} Submissions</span>
        {loading && (
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{
              display: "block !important)",
            }}
            wrapperClass="bars"
            visible={true}
          />
        )}
      </center>

      {!loading && (
        <div className={styles.dashboard}>
          <TextBox
            placeholder={"Search"}
            label="Search By Name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onBlur={() => {}}
          />

          <EntriesGrid newPage={page} rowsPerPage={rowsPerPage} entries={filterSearchEntries(search)} />

          {/* <center> */}
          <TablePagination
            rowsPerPageOptions={[
              10,
              25,
              {
                label: "All",
                value: filterSearchEntries(search).length,
              },
            ]}
            colSpan={3}
            count={filterSearchEntries(search).length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          {/* </center> */}
        </div>
      )}
    </div>
  );
}

export default SubmissionsMain;
