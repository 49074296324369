import TextBox from "../common/TextBox";
import styles from "./RadioButton.module.scss";

function RadioButton(props) {
  return (
    <>
      <div className={styles.radiobutton}>
        <label>
          <input
            type="radio"
            value={props.value}
            name={props.stateToSet}
            onChange={(event) => {
              props.handleChange(event, props.stateToSet);
            }}
            checked={props.values[props.stateToSet] === props.value}
          />
          <span>{props.label}</span>
        </label>
        <br />
      </div>

      {props.values["Square-Feet"] === "Not Sure" &&
        props.stateToSet == "Square-Feet" &&
        props.value == "Not Sure" && (
          <>
            <br />
            <TextBox
              placeholder="Enter The Number of Rooms"
              label="If so, enter the amount of rooms"
              onChange={(event) => {
                props.handleChange(event, "Square-Feet-Rooms");
              }}
              value={props.values["Square-Feet-Rooms"] || ""}
              type="text"
              onBlur={(event) => {}}
              blurIncluded={false}
            />
          </>

          // <input
          //   type="text"
          //   onChange={(event) => {
          //     props.handleChange(event, "Square-Feet-Rooms");
          //   }}
          //   value={props.values["Square-Feet-Rooms"]}
          // />
        )}
    </>
  );
}

export default RadioButton;
