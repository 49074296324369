import styles from "./ImageListItem.module.scss";

function ImageListItem(props){

    return (
        <div className={styles["list-item"]}>
            <span className={styles.x} onClick={props.removeImage}>X</span>
            <span className={styles.name}>{props.name}</span>
        </div>
    )

}

export default ImageListItem;