import ModalBase from "./../common/Modal";
import styles from "./../gallery/GalleryModal.module.scss";

function ConfirmDialog(props) {
  return (
    <div className={styles["modal-container"]}>
      <ModalBase
        isOpen={props.lightboxOpen}
        close={() => props.setLightboxOpen(false)}
      >
        <div className={styles["modal-content"]} tabIndex="0">
          <div className={styles.confirm}>
            <h2>Delete Submission</h2>
            <p>This will permanently delete this submisison.</p>

            <div className={styles.buttons}>
                <button onClick={() => props.setLightboxOpen(false)}>Cancel</button>
                <button onClick={props.deleteSubmission}>Confirm</button>
            </div>
          </div>
        </div>
      </ModalBase>
    </div>
  );
}

export default ConfirmDialog;
