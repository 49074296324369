import { useState, useEffect } from "react";
import styles from "./../../styles/Steps.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import TextBox from "./../common/TextBox";
import { db, fileUpload } from "./../../firebase";
import { collection, addDoc } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import { set } from "date-fns";

import { installationMaterialQuestions } from "./Installation-MaterialQuestions";
import { refinishQuestions } from "./RefinishQuestions";
import { installationQuestions } from "./InstallationQuestions";
import { repairQuestions } from "./RepairQuestions";

function FourthStep(props) {
  const [profileValues, setProfileValues] = useState({
    name: "",
    email: "",
    "phone-number": "",
  });

  const [errors, setErrors] = useState({ email: false, "phone-number": false });
  const handleChange = props.handleChange;

  const [finalState, setFinalState] = useState([]);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (props.genericValues.type == "Refinish") {
      setQuestions(refinishQuestions);
    } else if (props.genericValues.type == "Installation/Material") {
      setQuestions(installationMaterialQuestions);
    } else if (props.genericValues.type == "Installation Only") {
      setQuestions(installationQuestions);
    } else if (props.genericValues.type == "Repair") {
      setQuestions(repairQuestions);
    }
  }, [props.genericValues.type]);

  function checkErrors(event, input) {
    if (event.target.value == "") {
      setErrors((errors) => {
        return { ...errors, [input]: "" };
      });
    }
  }

  function handleProfileChange(event, input) {
    let value = event.target.value;
    if (input == "email" && event.target.value.length < 1) {
      errors.email =
        event.target.value.replace(/\s/g, "").length < 1
          ? "Cannot leave email blank!"
          : "";
    } else if (input == "email") {
      errors.email = validEmailRegex.test(event.target.value)
        ? ""
        : "Email is not valid!";
    }

    if (input == "phone-number") {
      const formattedPhoneNumber = formatPhoneNumber(event.target.value);
      // we'll set the input value using our setInputValue
      value = formattedPhoneNumber;
      if (value.length == 14) {
        errors["phone-number"] = "";
      } else {
        errors["phone-number"] = "Enter a valid phone number of 10 digits";
      }
    }
    setProfileValues((values) => {
      return {
        ...values,
        [input]: value,
      };
    });
  }

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
      return phoneNumber;
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const isDisabled =
    profileValues.name != "" &&
    profileValues.email != "" &&
    profileValues["phone-number"] != "" &&
    !errors.email &&
    !errors["phone-number"]
      ? false
      : true;

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  async function submitHandler() {
    if (errors.email == "") {
      let finalArray = [];

      questions.forEach((question, index) => {
        let item = {};
        item.index = index + 1;
        item.question = question.q;
        item.answer =
          question.stateToSet == "Square-Feet" &&
          props.values[question.stateToSet] == "Not Sure"
            ? props.values[question.stateToSet] +
              ", but it's " +
              props.values["Square-Feet-Rooms"] +
              " rooms"
            : props.values[question.stateToSet];
        item.stateToSet = question.stateToSet;
        finalArray.push(item);
      });

      // setFinalState(finalArray);

      await addDoc(collection(db, "submissions"), {
        // uid: props.user.uid,
        name: profileValues.name,
        email: profileValues.email,
        number: profileValues["phone-number"],
        date: new Date().valueOf(),
        authProvider: "local",
        data: finalArray,
        zipcode: props.genericValues.zipcode,
        type: props.genericValues.type || "",
        inspirationURL: props.values.imageURL || "",
        spaceURL: props.values.spaceURL || "",
        active: true,
      });
      if (props.values?.imageURL?.length > 0) {
        props.images.forEach(image => {
          fileUpload(
            image,
            `/images/${profileValues.email}/${image.name}`
          );
        })
      }

      if (props.values?.spaceURL?.length > 0) {
        props.spaceImages.forEach(image => {
          fileUpload(
            image,
            `/images/${profileValues.email}/${image.name}`
          );
        })
      }
      // console.log(finalArray)
      const emailObj = {
        name: profileValues.name,
        email: profileValues.email,
        number: profileValues["phone-number"],
        zipcode: props.genericValues.zipcode,
        type: props.genericValues.type,
        data: finalArray
          .map((item) => item.index + ") " + item.question + "\n" + item.answer)
          .join("\n\n"),
          
      };
      emailjs
        .send(
          process.env.REACT_APP_EMAIL_1,
          process.env.REACT_APP_EMAIL_2,
          emailObj,
          process.env.REACT_APP_EMAIL_3
        )
        .then(
          (result) => {},
          (error) => {

          }
        );

      props.next();
    }
  }

  return (
    <div
      className={
        props.direction == "forward"
          ? styles[`steps-container-forward`]
          : styles[`steps-container-back`]
      }
    >
      <div className={styles["button-div"]}>
        <button style={{ float: "left" }} onClick={props.back}>
          Back <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>

      <h2>Enter Your Info</h2>

      <TextBox
        placeholder="Enter Your Full Name"
        label="Full Name"
        onChange={(event) => {
          handleProfileChange(event, "name");
        }}
        onBlur={(event) => {}}
        type="text"
        value={profileValues.name}
      />

      <TextBox
        placeholder="Enter Your Email"
        label="Email"
        onChange={(event) => {
          handleProfileChange(event, "email");
        }}
        onBlur={(event) => checkErrors(event, "email")}
        type="text"
        value={profileValues.email}
      />

      {errors.email != "" && (
        <p className={styles["error-message"]}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          {" " + errors.email}
        </p>
      )}

      <TextBox
        placeholder="Enter Your Phone Number"
        label="Phone Number"
        onChange={(event) => handleProfileChange(event, "phone-number")}
        onBlur={(event) => {}}
        type="text"
        value={profileValues["phone-number"]}
      />

      {errors["phone-number"] != "" && (
        <p className={styles["error-message"]}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          {" " + errors["phone-number"]}
        </p>
      )}

      <br />

      <div className={styles["button-div"]}>
        <button style={{ float: "left" }} onClick={props.back}>
          Back <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>

      <center>
        <button
          disabled={isDisabled}
          className={styles.submit}
          onClick={submitHandler}
        >
          Schedule Now
        </button>
      </center>
    </div>
  );
}
export default FourthStep;
