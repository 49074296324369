import styles from "./LandingPageMain.module.scss";
import Tabs from "./../tabs/Tabs";
import TabTitle from "./../tabs/tabtitle";
import LandingPageTemplates from "./LandingPageTemplates";
import ActiveLandingPages from "./ActiveLandingPages";
import { useState } from "react";

function LandingPageMain() {
  const [selectedTab, setSelectedTab] = useState("Active Pages");

  return (
    <div className={styles["landing-page-container"]}>
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
        <TabTitle title="Active Pages" />
        <TabTitle title="Templates" />
      </Tabs>

      {selectedTab === "Templates" && <LandingPageTemplates />}
      {selectedTab === "Active Pages" && <ActiveLandingPages />}
    </div>
  );
}

export default LandingPageMain;
