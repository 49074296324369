import React from "react";
import Modal from "react-modal";
import "./Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0,0,0,0.6)",
  },
};

const spanStyles = {
  position: "absolute",
  top: "30px",
  right: "20px",
  fontSize: "32px",
  cursor: "pointer",
  color: "#f6f3ed"
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function ModalBase(props) {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.close}
        style={customStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={500}
      >
        <span style={spanStyles} onClick={props.close}>
          <FontAwesomeIcon icon={faXmark} />
        </span>

        {props.children}
      </Modal>
    </div>
  );
}

export default ModalBase;
