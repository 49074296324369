import { useState, useRef } from "react";
import TextEditor from "./TextEditor";
import TextBox from "./../common/TextBox";
import TextArea from "./../common/TextArea";
import { db } from "./../../firebase";
import { collection, addDoc } from "firebase/firestore";
import styles from './CreateBlog.module.scss';
import { useNavigate } from "react-router-dom";

function CreateBlog() {
  const navigate = useNavigate()
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const quillObj = useRef(null);


  async function handleSubmit() {
    await addDoc(collection(db, "blogs"), {
      title: title,
      description: description,
      data: quillObj.current.getEditor().root.innerHTML,
      date: new Date().valueOf(),
    }).then((data) => navigate("./../blog/" + data.id))
  }

  return (
    <div className={styles.container}>
      <h1 style={{ textAlign: "center" }}>Create Blog Post</h1>
      <TextBox
        placeholder={"Title"}
        label="Title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        onBlur={() => {}}
      />
      <TextArea
        placeholder="Description"
        label="Description"
        onChange={(event) => setDescription(event.target.value)}
        value={description}
        type="text"
      />
      <TextEditor value={""} quillObj={quillObj}/>
      <button className={styles.btn} onClick={handleSubmit}>Post Blog</button>
    </div>
  );
}

export default CreateBlog;
