import { useState } from "react";
import styles from "./Checkbox.module.scss";

function Checkbox(props) {
  const handleChange = () => {
    // console.log("The checkbox was toggled");
  };

  const [isChecked, setIsChecked] = useState(false || props.checked);

  return (
    <div className={styles.checkbox}>
      <label>
        <input
          type="checkbox"
          value={props.label}
          onChange={(event) => {
            setIsChecked(!isChecked);
            props.handleFilter(event, props.label, !isChecked);
          }}
          name={props.label}
          checked={isChecked || props.checked}
        />

        {props.label}
      </label>
      <br />
    </div>
  );
}

export default Checkbox;
