import Imageblock from "./ImageBlock";
import styles from "./ImageBlock.module.scss";

function Photogrid(props) {



  // Organize picture data array into an array of picture components
  const pics = props.photos.map((pic, index) => {
    return <Imageblock setIndex={props.setIndex} openModal={props.openModal} index={index} key={index} src={pic} />;
  });

  // Return the picture components wrapped in a container
  return <div className={styles.container}>{pics}</div>;
}

export default Photogrid;
