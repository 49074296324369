import styles from "./Explore.module.scss";
import { useNavigate } from "react-router-dom";

function Explore() {
  const navigate = useNavigate();

  return (
    <section className={styles.explore}>
      <div className={styles["explore-image"]}>
        <div className={styles["main-div-center"]}>
          <h1>Find Your Inspiration</h1>
          <button onClick={() => navigate("gallery")}>View Our Gallery</button>
        </div>
      </div>
    </section>
  );
}
export default Explore;
