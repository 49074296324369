import styles from "./Login.module.scss";
import TextBox from "./../common/TextBox";
import { useState } from "react";
import { logInWithEmailAndPassword } from "./../../firebase";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function Login(props) {
  document.title = "My Flooring Guy Now | Log In"

  const navigate = useNavigate();

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({ username: false, both: false });
  function handleChange(event, input) {
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  return (
    <div className={styles["main-div"]}>
      <h2>Log In</h2>

      <TextBox
        value={values.username}
        placeholder="Enter Your Username"
        label="Username"
        onChange={(event) => {
          handleChange(event, "username");
        }}
        onBlur={(event) => {
          setErrors({ username: false, both: false });
        }}
        type="text"
      />

      <TextBox
        value={values.password}
        placeholder="Enter Your Password"
        label="Password"
        onChange={(event) => {
          handleChange(event, "password");
        }}
        onBlur={(event) => {
          setErrors({ username: false, both: false });
        }}
        type="password"
      />

      {errors.username == true && errors.both == false && (
        <span className={styles["error-message"]}>
          <FontAwesomeIcon
            style={{ marginRight: "5px" }}
            icon={faCircleExclamation}
          />
          Invalid email
        </span>
      )}

      {errors.username == true && errors.both == true && (
        <span className={styles["error-message"]}>
          <FontAwesomeIcon
            style={{ marginRight: "5px" }}
            icon={faCircleExclamation}
          />
          Wrong password
        </span>
      )}

      <br />
      <button
        onClick={() => {
          const logReturn = function () {
            return logInWithEmailAndPassword(values.username, values.password);
          };
          logReturn().then((data) => {
            if (data == true) {
              navigate("/");
              setErrors({ username: false, both: false });
            } else if (
              data.message == "Firebase: Error (auth/invalid-email)."
            ) {
              setErrors({ username: true, both: false });
              return;
            } else if (
              data.message == "Firebase: Error (auth/wrong-password)." ||
              "Firebase: Error (auth/internal-error)."
            ) {
              setErrors({ username: true, both: true });
              return;
            }
          });
        }}
        disabled={values.username == ""}
        className={styles["login-btn"]}
      >
        Log In
      </button>

      {/* <p>
        Don't have an account?{" "}
        <a onClick={() => navigate("/register")}>Register here!</a>
      </p>

      <p>
        <a onClick={() => navigate("/register")}>Forgot Password?</a>
      </p> */}
    </div>
  );
}

export default Login;
