export const repairQuestions = [
    {
        q: "What material are you looking to repair?",
        stateToSet: "Material-Type",
        list: [
        {
          value: "Vinyl/Laminate",
          label: "Vinyl/Laminate",
        },
        {
          value: "Solid Hardwood",
          label: "Solid Hardwood",
        },
        {
          value: "Engineered Hardwood",
          label: "Engineered Hardwood",
        },
      ]},
  
    {
      q: "How large is the repair area?",
      stateToSet: "Square-Feet",
      list: [
      {
        value: ">50",
        label: ">50",
      },
      {
        value: "50-100",
        label: "50-100",
      },
      {
        value: "100-200",
        label: "100-200",
      },
      {
        value: "200+",
        label: "200+",
      },
    ]},
  

    {
        q: "Do you have extra flooring in your home?",
        stateToSet: "Extra-Flooring",
        list: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
      ]},

      {
        q: "If a repair is not possible, would you be open to replacing it?",
        stateToSet: "Material-Selection",
        list: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
        {
            value: "No",
            label: "Maybe",
          },
      ]},
  ];
  