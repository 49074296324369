import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import styles from "./ScrollButton.module.scss";
import { motion } from "framer-motion"

function ScrollButton() {
  const [fade, setFade] = useState(false);
  const [visible, setVisible] = useState(false);


  const toggleVisible = () => {
    const scrolled = window.pageYOffset;
    if (scrolled > 50) {
      setVisible(true);
      
    } else if (scrolled <= 50) {
      setVisible(false);
    }

    if (scrolled > 150) {
        setFade(true);
        
      } else if (scrolled <= 150) {
        setFade(false);
      }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    visible && <motion.button animate={{ opacity: fade ? 1 : 0 }} className={styles["scroll-button"]} onClick={scrollToTop}>
      <span><FontAwesomeIcon icon={faArrowUp} /></span>
    </motion.button>
  );
}

export default ScrollButton;
