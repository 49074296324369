import TextArea from "./../common/TextArea";
import styles from "./../../styles/Steps.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ImageListItem from "./ImageListItem";
import { useEffect } from "react";

function ThirdStep(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = props.handleChange;

  function imageChange(event, type) {
    const imagesCopy =
      type == "space"
        ? [...props.spaceImages, event.target.files[0]]
        : [...props.images, event.target.files[0]];
    type == "space"
      ? props.setSpaceImages(imagesCopy)
      : props.setImages(imagesCopy);
    type == "space" &&
      props.setValues((values) => {
        return {
          ...values,
          spaceURL: imagesCopy.map((item) => item.name),
        };
      });
    type == "inspiration" &&
      props.setValues((values) => {
        return {
          ...values,
          imageURL: imagesCopy.map((item) => item.name),
        };
      });
  }

  function removeImage(type, name) {
    type == "space" &&
      props.setSpaceImages(
        props.spaceImages.filter((image) => image.name != name)
      );
    type == "inspiration" &&
      props.setImages(props.images.filter((image) => image.name != name));
    type == "space" &&
      props.setValues((values) => {
        return {
          ...values,
          spaceURL: values.spaceURL.filter((image) => image != name),
        };
      });
    type == "inspiration" &&
      props.setValues((values) => {
        return {
          ...values,
          imageURL: values.imageURL.filter((image) => image != name),
        };
      });
  }

  return (
    <div
      className={
        props.direction == "forward"
          ? styles[`steps-container-forward`]
          : styles[`steps-container-back`]
      }
    >
      <div className={styles["button-div"]}>
        <button style={{ float: "left" }} onClick={props.back}>
          Back <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <button
          style={{ float: "right" }}
          disabled={props.values.comments == ""}
          onClick={props.next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <br />
      </div>

      <br />
      <div className={styles.inputs}>
        <div className={styles["input-div"]}>
          <p className={styles["input-label"]}>Upload Your Space (optional)</p>
          <input
            id="actual-btn"
            type="file"
            multiple
            hidden
            onChange={(event) => {
              imageChange(event, "space");
            }}
          />

          <label htmlFor="actual-btn" className={styles["photo-input"]}>
            Choose File
          </label>

          <div className={styles.files}>
            {props.values?.spaceURL &&
              props.values?.spaceURL.length > 0 &&
              props.values.spaceURL.map((image, index) => {
                return (
                  <ImageListItem
                    key={index}
                    removeImage={() => removeImage("space", image)}
                    name={image}
                  />
                );
              })}
          </div>
        </div>
        <div className={styles["input-div"]}>
          <p className={styles["input-label"]}>
            Upload Your Inspiration (optional)
          </p>
          <input
            id="inspo-btn"
            type="file"
            multiple
            hidden
            onChange={(event) => {
              imageChange(event, "inspiration");
            }}
          />

          <label htmlFor="inspo-btn" className={styles["photo-input"]}>
            Choose File
          </label>

          <div className={styles.files}>
            {props.values?.imageURL &&
              props.values?.imageURL.length > 0 &&
              props.values.imageURL.map((image, index) => {
                return (
                  <ImageListItem
                    key={index}
                    removeImage={() => removeImage("inspiration", image)}
                    name={image}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <br />

      <TextArea
        placeholder="Enter Additional Comments"
        label="Additional Comments"
        onChange={(event) => {
          handleChange(event, "Comments");
        }}
        value={props.values.Comments || ""}
        type="text"
      />

      {/* <button onClick={submitHandler}>Submit</button> */}

      <div className={styles["button-div"]}>
        <br />
        <button style={{ float: "left" }} onClick={props.back}>
          Back <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <button
          style={{ float: "right" }}
          disabled={props.values.comments == ""}
          onClick={props.next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
}
export default ThirdStep;
