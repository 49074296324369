import React, { useMemo } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import { fileUpload } from "./../../firebase";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import ImageResize from "quill-image-resize-module-react";
import "./TextEditor.scss";

const TextEditor = (props) => {
  const quillObj = props.quillObj;

  Quill.register("modules/imageResize", ImageResize);
  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          ["link", "image"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const storage = getStorage();

  async function imageHandler(event) {
    // console.log(event);
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      formData.append("image", file);

      var fileName = file.name;

      // console.log(fileName);
      // console.log(formData);

      await uploadFiles(file, fileName, quillObj);
    };
  }

  async function uploadFiles(uploadFileObj, filename, quillObj) {
    const res = await fileUpload(uploadFileObj, `/blogImages/${filename}`);
    // console.log(res);
    getDownloadURL(ref(storage, res.metadata.fullPath)).then((imageUrl) => {
      const range = quillObj.current.selection.index;
      quillObj.current.getEditor().insertEmbed(range, "image", imageUrl);
    });
  }

  return (
    <div>
        <ReactQuill
          ref={quillObj}
          theme="snow"
          modules={modules}
          formats={formats}
          defaultValue={props.value}
        />
    </div>
  );
};

export default TextEditor;
