import { useState, useEffect } from "react";
import TextBox from "./../common/TextBox";
import styles from "./../../styles/Steps.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { typeQuestions } from "./TypeQuestions";
import RadioButton from "./RadioButton";

function FirstStep(props) {
  const [errors, setErrors] = useState({ zipcode: false });
  // const [isValidated, setValidated] = useState(false);
  const handleChange = props.handleChange;

  useEffect(() => {
    checkAgain(props.values.zipcode, "zip");
    checkAgain(props.values.type, "type");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function checkErrors(event) {
    if (isNaN(event.target.value) == false && event.target.value.length == 5) {
      event.type === "blur" && setErrors({ zipcode: false });
      // event.type === "change" && setValidated(true);
      checkAgain(props.values.type, "type");
      return false;
    } else {
      event.type === "blur" && setErrors({ zipcode: true });
      // event.type === "change" && setValidated(false);
      return true;
    }
  }

  function checkAgain(value, section) {
    if (section == "zip" && isNaN(value) == false && value.length == 5) {
      return true;
    } else if (section == "type" && value != "") {
      return true;
    } else {
      return false;
    }
  }

  const isValidated =
    checkAgain(props.values.zipcode, "zip") &&
    checkAgain(props.values.type, "type");

  return (
    <div
      className={
        props.direction === "forward"
          ? styles[`steps-container-forward`]
          : styles[`steps-container-back`]
      }
    >
      <div className={styles["button-div"]}>
        <button
          style={{ float: "right", marginTop: "12px" }}
          disabled={!isValidated}
          onClick={props.next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <h2>Hey! First things first, where are you looking to do the work?</h2>
      <p>
        This will help us better understand what resources we will allocate for
        you job.
      </p>
      <TextBox
        placeholder="Enter Your Zipcode"
        label="Zipcode"
        onChange={(event) => {
          checkErrors(event);
          handleChange(event, "zipcode");
        }}
        onBlur={(event) => checkErrors(event)}
        value={props.values.zipcode}
        error={errors.zipcode}
        type="text"
        blurIncluded={true}
      />

      {errors.zipcode === true && isValidated === false && (
        <span className={styles["error-message"]}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          {props.values.zipcode === ""
            ? " Cannot leave field blank"
            : " Enter a valid zipcode"}
        </span>
      )}

      <br />
      <h2>Also, what type of project are you looking to accomplish?</h2>

      {typeQuestions.map((question, num) => {
        return (
          <div key={num} className={styles["question-item"]}>
            {question.list.map((item) => {
              return (
                <RadioButton
                  key={item.value}
                  value={item.value}
                  onChange={(event) => checkErrors(event)}
                  handleChange={handleChange}
                  values={props.values}
                  stateToSet={question.stateToSet}
                  label={item.label}
                />
              );
            })}
          </div>
        );
      })}

      <div className={styles["button-div"]}>
        <button
          style={{ float: "right", marginTop: "12px" }}
          disabled={!isValidated}
          onClick={props.next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
}
export default FirstStep;
