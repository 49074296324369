import "./../../styles/TextBox.scss";
import { useState } from "react"

function TextArea(props) {
  const [isFocused, setFocused] = useState(false);
  // add active class and placeholder
  const handleFocus = (e) => {
    setFocused(true)
    const target = e.target;
    target.parentNode.classList.add("active");
    target.setAttribute("placeholder", target.getAttribute("data-placeholder"));
  };

  // remove active class and placeholder
  const handleBlur = (e) => {
    setFocused(false)
    const target = e.target;
    if (target.value == "") {
      target.parentNode.classList.remove("active");
    }
    target.removeAttribute("placeholder");
  };

  // get DOM elements
  const floatContainers = document.querySelectorAll(".textarea-container");

  floatContainers.forEach((element) => {
    if (element.querySelector("textarea").value != "") {
      element.classList.add("active");
    }
  });

  return (
    <div className={"textarea-container" + (props.value != "" ? " active" : "") + (isFocused == true ? " active" : "")}>
      <label htmlFor="floatArea">{props.label}</label>
      <textarea
        onChange={props.onChange}
        value={props.value}
        data-placeholder={props.placeholder}
        onBlur={handleBlur}
        onFocus={handleFocus}
        name="floatArea"
      />
    </div>
  );
}

export default TextArea;
