export const installationMaterialQuestions = [
  {
    q: "What type of material are you looking to install?",
    stateToSet: "Material-Type",
    list: [
      {
        value: "Vinyl/SPC",
        label: "Vinyl/SPC",
      },
      {
        value: "Laminate",
        label: "Laminate",
      },
      {
        value: "Solid Hardwood",
        label: "Solid Hardwood",
      },
      {
        value: "Engineered Hardwood",
        label: "Engineered Hardwood",
      },
      {
        value: "Not Sure",
        label: "Not Sure, Let's Talk!",
      },
    ],
  },

  {
    q: "What color are you looking for?",
    stateToSet: "Material-Color",
    list: [
      {
        value: "Dark Brown",
        label: "Dark Brown",
      },
      {
        value: "Light Brown",
        label: "Light Brown",
      },
      {
        value: "Dark Gray",

        label: "Dark Gray",
      },
      {
        value: "Light Gray",
        label: "Light Gray",
      },
      {
        value: "Natural Oak",
        label: "Natural Oak",
      },
      {
        value: "Not Sure, Let's Talk!",
        label: "Not Sure, Let's Talk!",
      },
    ],
  },

  {
    q: "How many square feet are you looking to install?",
    stateToSet: "Square-Feet",
    list: [
      {
        value: "100-250",
        label: "100-250",
      },
      {
        value: "250-500",
        label: "250-500",
      },
      {
        value: "500-750",
        label: "500-750",
      },
      {
        value: "750-1200",
        label: "750-1200",
      },
      {
        value: "1200+",
        label: "1200+",
      },
      {
        value: "Not Sure",
        label: "Not Sure",
      },
    ],
  },

  {
    q: "Will there be stairs involved in the project?",
    stateToSet: "Stairs",
    list: [
      {
        value: "Yes",
        label: "Yes",
      },
      {
        value: "No",
        label: "No",
      },
      {
        value: "Not sure, let's discuss",
        label: "Not sure, lets discuss!",
      },
    ],
  },
];
